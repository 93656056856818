import { useDispatch } from "react-redux";
import { getLocalStorage } from "../../Network/ApiService";
import { setUserDetails } from "../../Store/Actions/AuthModule/authActionCreator";
import { ls_vars } from "../Constants";

export const isAuth = () => {
  const accessToken = getLocalStorage(ls_vars.ACCESS_TOKEN);
  return Boolean(accessToken);
};

export const handleLogout = () => {
  const dispatch = useDispatch();
  dispatch(setUserDetails([]));
  localStorage.clear();
};
