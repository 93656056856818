import images from "../../Assets/images";

const bookingSteps = [
  {
    id: 1,
    name: "Delivery",
    image: images.delivery,
    detail: "delivery_information_message",
    information: [
      { step: 1, title: "scheduling_information" },
      { step: 2, title: "booking_date" },
      { step: 3, title: "booking_time" },
      { step: 4, title: "booking_details" },
      { step: 5, title: "additional_details" },
    ],
    data: {
      schedulingInformation: {},
      bookingDate: {
        selectedDate: "",
      },
      bookingTime: "",
      bookingSlotDetail: "",
      additionalDetail: {},
      timer: 600,
      bookingCreationData: {},
    },
  },
  {
    id: 2,
    name: "Recurring",
    image: images.recurring,
    detail: "recurring_information_message",
    information: [
      { step: 1, title: "scheduling_information" },
      { step: 2, title: "booking_date" },
      { step: 3, title: "booking_time" },
      { step: 4, title: "booking_details" },
      { step: 5, title: "additional_details" },
    ],
    data: {
      schedulingInformation: {},
      bookingTime: "",
      bookingSlotDetail: "",
      bookingDate: {
        startDate: "",
        endDate: "",
        repeatType: "",
        repeatWeekWise: "",
        repeatMonthWise: {
          monthWeek: "",
          monthDay: "",
        },
      },
      additionalDetail: {},
      timer: 600,
    },
  },
  {
    id: 3,
    name: "Multi-Vehicle",
    image: images.multiVehicle,
    detail: "multivehicle_information_message",
    information: [
      { step: 1, title: "scheduling_information" },
      { step: 2, title: "booking_date" },
      { step: 3, title: "booking_time" },
      { step: 4, title: "booking_details" },
      { step: 5, title: "additional_details" },
    ],

    data: {
      schedulingInformation: {},
      bookingDate: {
        selectedDate: "",
      },
      bookingTime: "",
      bookingSlotDetail: "",
      additionalDetail: {},
      timer: 600,
    },
  },
  {
    id: 4,
    name: "Resource-Only",
    image: images.resource,
    detail: "resource_only_information_message",
    information: [
      { step: 1, title: "scheduling_information" },
      { step: 2, title: "booking_date" },
      { step: 3, title: "booking_time" },
      { step: 4, title: "booking_details" },
      { step: 5, title: "additional_details" },
    ],
    data: {
      schedulingInformation: {},
      bookingDate: {
        selectedDate: "",
      },
      bookingTime: "",
      bookingSlotDetail: "",
      additionalDetail: {},
      timer: 600,
    },
  },
];

export default bookingSteps;
