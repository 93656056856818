const Translation = {
  invite_users: "Invite Users",
  send_invite: "Send Invites",
  resend_invite: "Re-send Invite",
  cancel_invite: "Cancel Invite",
  invite_sent: "Invite Sent",
  invite_sent_at: "Invite Sent at",
  save_details: "Save Details",
  email: "Email",
  email_optional: "Email (Optional)",
  mobile: "Mobile",
  role: "Role",
  error_email_required: "Email is required",
  error_valid_email: "Hmm… that email doesn't look valid",
  error_role_required: "Role is required",
  error_firstName_required: "First Name is required",
  error_access_point_required: "Accesspoint is required",
  error_something_went_wrong: "Something Went Wrong...",
  invite_success: "User Invite Successfully",
  error_login_again: "Login token expired, Please login again",
  access_denied: "You do not have access",
  invalid_email_domain:
    "Personal email addresses cannot be invited to voyage control, please use a work email",
  user_management: "User Management",
  add_another: "Add Another",
  invite_later: "Invite Later",
  close: "Close",
  filter: "Filter",
  please_select_site: "Please Select site",
  name_required: "Name is required",
  start_time_required: "Start Time is required",
  end_time_required: "End Time is required",
  select_site: "Select Site",
  resource_operating_time: "Resource Operating Times",
  all_day: "All Day",
  breaks: "Breaks",
  add_break: "Add Break",
  name: "Name",
  days_required: "Please Select Days",
  days_active: "Days Active",
  all_days: "All Days",
  all_accesspoint: "All Access Point",
  all_resources: "All Resource",
  start_time: "Start Time",
  end_time: "End Time",
  resources: "Resources",
  vehicles: "Vehicles",
  resource_required: "Resource is required",
  cancel: "Cancel",
  save: "Save",
  start: "Start",
  done: "Done",
  edit_break: "Edit Break",
  start_time_add_required: "Start Time add is required",
  end_time_add_required: "End Time add is required",
  remove: "Remove",
  resource_closure: "Resource Closures",
  add_resource_closure: "Add Resource Closures",
  edit_resource_closure: "Edit Resource Closures",
  dates: "Dates",
  start_date_required: "Start Date is required",
  end_date_required: "End Date is required",
  organization_Role_updated: "Organization Role Updated",
  clear_all: " Clear All",
  status: "Status",
  project_role: "Project Roles",
  organization: "Organization",
  first_name: "First Name",
  last_name: "Last Name",
  email_address: "Email Address",
  associated_organization: "Associated Organization",
  no_data_found: "No Data found",
  access_point: "Access Point",
  access_point_required: "Access Point is required",
  vehicle_type: "Vehicle type",
  vehicle_type_required: "Vehicle type is required",
  resource: "Resource",
  next: "Next",
  copied: "Copied",
  contact_desc:
    "Contact Site Super Steven with questions at steve@sitesuper.com.Download the mobile apps here to schedule and view your deliveries",
  full_site_plan: "Click here to view a full size site plan",
  site_plan: "Site Plan",
  on: "on",
  on_every: "On Every",
  vehicle: "Vehicle",
  vehicle_tracking: "Vehicle Tracking",
  select_role: "Select Role",
  please_select_time: "Please select time in open hour",
  please_select_time_in_resource: "Please select time in resource",
  no_resource_found: "No Resource Found",
  remove_last: "Remove last",
  remove_vehicle: "Remove vehicle",
  filters: "Filters",
  saved_details_success_fully: "Save Detail Successfully",
  last_name_required: "lastName required",
  phone_required: "Phone required",
  invalid_phone: "Phone invalid",
  can_not_remove_project_owner: "You cannot remove project owner",
  details: "Details",
  phone_number: "Phone Number",
  company: "Company",
  roles: "Roles",
  organization_role: "Organization Role",
  remove_user_from_project: "Remove from project",
  booking_list: "Booking List",
  all: "All",
  go: "GO",
  submit: "Submit",
  submit_booking: " Submit Booking",
  back: "Back",
  Additional_Comment: "Additional Comment",
  Comment: "Comment",
  company_name_required: "Company Name is required",
  full_name_is_required: "Full Name is required",
  email_not_valid: "Email is not valid",
  email_required: "Email is required",
  mobile_number_required: "Mobile Number is required",
  mobile_number_not_valid: "Mobile Number not valid",
  site_contact: "Site Contact",
  booking_contact: "Booking Contact",
  company_name: "Company Name",
  full_name: "Full Name",
  email_Address: "Email Address",
  Mobile_number: "Mobile Number",
  use_my_details: "Use My Details",
  select_options: "Please Select Option",
  text_field_required: "Text Field is Required",
  checkbox_required: "Checkbox is Required",
  file_upload_required: "File Upload is Required",
  comments_required: "Comments are Required",
  please_select_time_slot: "Please Select Time slot",
  Hours: "Hours",
  Minutes: "Minutes",
  Maximum: "Maximum",
  Minimum: "Minimum",
  booking: "booking",
  Get_Slots: "Get Slots",
  booking_which_time_on: "Select a slot for your booking on ",
  Show_more_time_slots: "Show more time slots",
  Afternoon: "Afternoon",
  Evening: "Evening",
  Morning: "Morning",
  comments: "Comments",
  title: "Title",
  phone: "Phone",
  question: " Question",
  file_uploaded: "File Uploaded SuccessFully",
  files: "Files",
  upload_new_file: "Upload new file",
  no_notes: "No notes yet",
  no_files: "No Files yet",
  no_notes_displayed: "Notes will display here when you add one",
  no_files_displayed: "Files will display here when you add one",
  add_note: "Add Note",
  action: "Action",
  action_by: "Actioned By",
  show_linked_recurring: "Show Linked Recurring Bookings",
  For_Approval: "For Approval",
  Scheduled: "Scheduled",
  Checked_In: "Checked In",
  Checked_out: "Checked out",
  Checkout: "Checkout",
  Declined: "Declined",
  Cancelled: "Cancelled",
  Refused: "Refused",
  Refuse: "Refuse",
  cant_undo: "You can’t undo this action",
  are_you_sure: "Are you sure you want to permanently delete this",
  are_you_sure_cancel_booking: "Are you sure you want to cancel this booking?",
  are_you_sure_cancel_all_booking:
    "Are you sure you want to cancel all bookings in this recurring series?",
  are_you_sure_decline_booking:
    "Are you sure you want to decline this booking?",
  yes: "Yes",
  no: "No",
  linked_booking: "Linked Bookings",
  notification_preference: "Notification Preferences",
  my_booking_content:
    "This area allows you to set your preferences for being notified about the bookings you create. You can control which actions you are notified about and how you are notified.",
  my_org_booking_content:
    "This area allows you to set your preferences for being notified about all bookings created by users in your organisation associated with this project. You are seeing this area because you have the organisational role of ‘Admin’. You can control which actions you are notified about and how you are notified.",
  all_project_booking_content:
    "This area allows you to set your preferences for being notified about all bookings created by users associated with this project. You are seeing this area because you have the project role of ‘Manager’ or ‘Admin’. You can control which actions you are notified about and how you are notified.",
  save_changes: "Save Changes",
  to_all_recurring: "All Bookings",
  this_only: "This Booking Only",
  part_of_recurring_decline:
    "This booking is part of a recurring booking series. Do you want to decline just this booking or all bookings in the series?",
  part_of_recurring_approve:
    "This booking is part of a recurring booking series. Do you want to approve just this booking or all bookings in the series?",
  part_of_recurring_cancel:
    "This booking is part of a recurring booking series. Do you want to cancel just this booking or all bookings in the series?",
  edit: "Edit",
  move: "Move",
  download_pass: "Download Pass",
  expected_vehicles: "Expected Vehicles",
  scheduling_information: "Scheduling Information",
  scheduling_information_tooltip:
    "If there are some required information missing in the options, feel free to change it in the project management section or contact with your project admin.",
  okay: "Okay",
  collapse: "Collapse",
  required_text_indication_text: "Fields marked with a * must be completed",
  check_in_time: "Check In Time",
  check_out_time: "Check Out Time",
  cancel_booking: "Cancel Booking",
  cancel_all_booking: "Cancel All Booking",
  decline_booking: "Decline Booking",
  edit_booking_details: "Edit Booking Details",
  booking_details: "Booking Details",
  additional_details: "Additional Details",
  preference_updated_successfully:
    "Notification Preference Updated Successfully",
  automatic_approved: "Automated Approval",
  you_are_admin: "You will be the company admin",
  new_booking: "New Booking",
  select_country: "Select Country",
  required_country: "Country Name is required",
  country: "Country",
  confirm_move_booking: "Confirm Moving Booking",
  current_date_slot: "Current Date Slot",
  current_time_slot: "Current Time Slot",
  move_which_time_on: "Move to which time on",
  show_more_slot: "Show more time slots",
  move_booking: "Move Booking",
  remove_user_confirm: "Are you sure you want to remove this user from ",
  page: "Select Page",
  join_company: "Join Company",
  log_into: "Log Into",
  dont_recognize_company: "I don't recognize this company",
  units: "Units",
  export_tooltip_message:
    "You’re export will start downloading automatically when ready. You’ll also be emailed a link to download it later.",
  select_booking: "A booking type must be selected to continue",
  what_kind_of_booking: "What kind of booking do you want to make?",
  expected_number_vehicle: "Expected number of vehicles",
  email_us: "Email us at ",
  please_contact_us: "Please Contact Us",
  previous: "Previous",
  please_select_slot: "Please Select Slot",
  please_select_date: "Please Select Date",
  booking_moved: "Booking Moved",
  booking_edited: "Booking Edited",
  logout: "Log Out",
  help: "Help",
  none: "None",
  created_by: "Created By",
  hours_restricted_tooltip_text:
    "Hours restricted before delivery defines a period between the current time and a set number of hours in the future where making a booking is restricted.",
  days_allowed_in_advance_tooltip_text:
    "Days allowed in advance defines a period between the current date and a set  of days in the future where bookings can be made.",
  admin_portal: "Admin Portal",
  coming_soon: "Coming Soon",
  project_name: "Project Name",
  create_account: "Create Account",
  time_left_complete_booking: "Time left to complete booking",
  reselect_timeslot: "Reselect Timeslot",
  calendar: "Calendar",
  mine: "MINE",
  time: "Time",
  booking_type: "Booking Type",
  contact_org: "Contact Organization",
  resource_availability_updated: "Resource Availability Updated Successfully",
  week: "Week",
  day: "Day",
  show_weekends: "Show weekends",
  show_canceled: "Show canceled bookings",
  today: "Today",
  loading_availability: "Loading availability, please wait",
  cant_move_on_unavailable_timeslot:
    "You cannot move a booking to a time slot that is unavailable",
  no_slot_available: "No slots are available for this booking",
  you_dont_have_permission: "You don’t have permission to access this booking",
  cant_booking_cause: "That booking cannot be moved because it is ",
  you_dont_have_permission_for_move_booking:
    "You do not have permission to move that booking",
  cant_move_booking_in_past:
    "You cannot move a booking to a time slot in the past",
  max_unit_should_not_negative: "maxUnit not pass in negative number",
  please_enter_max_quantity: "Please enter max quantity",
  please_select_project: "Please Select Project",
  replace: "Replace",
  number_of_days_booking_label: "Days allowed in advance ",
  number_of_hours_booking_label: "Hours restricted before delivery ",
  advance_booking: "Advanced Booking Cutoff",
  override_constraints:
    "Allow override booking constraints for Project Manager and Admin",
  override_constraints_tooltip:
    "Checking this box means that users with the role Project Manager or Project Admin are allowed to create bookings outside the advanced booking cutoff periods.",
  error_end_date_required: "End Date is required",
  error_star_date_required: "Start Date is required",
  error_field_required: "This field is required",
  success_response_site_availability:
    "Site Availability Create/Update Successfully",
  hours_of_operation: "Hours of Operation",
  break: "Breaks",
  error_name_required: "Name is required",
  error_starttime_required: "Start-Time is required",
  error_end_time_required: "End-Time is required",
  day_active: "Days Active",
  site_closure: "Site Closures",
  add_site_closure: "Add Site Closures",
  edit_site_closure: "Edit Site Closures",
  error_message_site_availability: "Please select time in hour operation",
  error_message_availability: "Please select time in resource",
  to: "TO",
  please_select_days: "Please Select Days",
  make_booking_info_message: "Booking creation message",
  welcome_text: "Welcome To Voyage Control",
  pls_login: "Please Log In",
  password: "Password",
  forgot_password: "Forgot Password",
  login: "Login",
  check_info: "Checking info",
  success_message: "Login Successfully",
  error_password_required: "Password is required",
  error_valid_password: "Hmm… that password isn’t right",
  comman_error_message:
    "An account with this email address does not exist. Please contact the project administrator to request an invite.",
  reset_password: "Reset Password",
  welcometext_reset: "The password shoud have atleast 8 characters",
  confirmpassword: "Confirm Password",
  error_confirm_password_required: "Confirm Password is required",
  error_password_upper: "At least 1 uppercase character",
  error_password_lower: "At least 1 lowercase character",
  error_password_number: "At least 1 digit",
  error_password_special: "At least 1 special character",
  error_password_min: "Minimum 8 characters",
  error_password_max: "Maximum 16 characters",
  error_password_not_match: "The passwords do not match",
  backtologin: "Back to login",
  welcometext_forgot:
    "Please enter your email and we will send you instructions to reset your password.",
  set_your_company: "Set Up Your Company",
  companyname: "Company Name",
  address1: "Address Line 1",
  address2: "Address Line 2",
  town_city: "Town/City",
  region_state: "Region/State",
  zip_code: "Zipcode",
  company_success_message: "Congrats you have joined Orgnaization",
  error_companyname_required: "Company Name is required",
  error_address_required: "Address is required",
  error_country_required: "Country is Required",
  profile_welcome_text: "Set Up Your Profile",
  firstname: "First Name",
  lastname: "Last Name",
  phoneNumber: "Phone Number",
  error_firstname_required: "First Name is required",
  error_lastname_required: "Last Name is required",
  error_phonenumber_required: "Phone Number is required",
  error_valid_phonenumber: "Please enter valid Phone Number",
  details_saved: "Save Detail Successfully",
  my_account: "My Account",
  my_details: "My Details",
  update_password: "Update Password",
  my_preferences: "My Preferences",
  calendar_start_day: "Calendar Start Day",
  my_roles: "My Roles",
  added_contact: "Successfully Add Contact",
  deleted_contact: "Successfully Delete Contact",
  upload: "Upload",
  pls_Signup: "Please Sign Up",
  createaccount: "Create Account",
  org_role: "Organization Role",
  choose_project: "Choose Project",
  this_project_only: "This project only",
  user_removed: "User is removed From Organization",
  org_role_updated: "organization Role updated",
  no_user_found: "No User found",
  current_password: "Current Password",
  new_password: "New Password",
  new_password_again: "New Password Again",
  current_password_is_required: "Current password is required",
  org_success_message: "Organization Details are Updated",
  error_organization_name_required: "Organization Name is required",
  error_organization_address1_required: "Address Line 1 is required",
  error_organization_address2_required: "Address Line 12 is required",
  error_organizationTown_required: "Town is required",
  error_organizationState_required: "State is required",
  error_organizationZip_required: "Zipcode is required",
  error_organizationLogo_required: "Logo is required",
  error_organizationLogo_invalid: "Please upload valid image file",
  organization_details: "Organization Details",
  organization_logo: "Organization Logo",
  error_no_white_space: "White space is not allowed",
  password_length_range: "between 8 and 16 characters",
  enter_valid_password: "Enter Valid Password",
  details_heading_text: "Details",
  details_image_text: "Images & Home Screen",
  label_sitename: "Project Name",
  label_siteadd: "Project Address",
  label_logo: "Logo",
  label_home_banner: "Home Banner",
  label_home_screen_message: "Home Screen Message",
  error_image_invalid: "Please upload valid image file",
  error_more_than_year_in_advance:
    "Entering a number greater than 365 will cause performance issues in this project",
  error_siteLogo_required: "Site Logo is required",
  error_homebanner_required: "Banner Image is required",
  error_sitename_required: "Site Name is required",
  error_siteadd_required: "Site Address is required",
  error_homemessage_required: "Home Screen Message is required",
  contact_heading_text: "Contacts",
  add_contact: "Add New Contact",
  edit_contact: "Edit Contact",
  label_name: "Name",
  label_position: "Position",
  label_phone: "Phone Number (Optional)",
  btn_add: "Add",
  btn_remove: "Remove",
  btn_edit: "Save",
  btn_save: "Save",
  change_associated_organization: "Change Associated Organization",
  confirm: "Confirm",
  new_organization: "New Organization",
  changed_organization: "Organization Changes SuccessFully",
  changed_organization_tooltip:
    "Associated Organization may be changed. Check user action menu to find 'Change Associated Organization' option.",
  error_position_required: "Position is required",
  resourse_add: "New Resource Added",
  vehicle_list_updated: "Vehicle List Updated",
  resource_remove: "Resource Removed",
  resource_edit: "Resource Updated",
  access_point_heading_text: "Access Points",
  btn_cancel: "Cancel",
  btn_GO: "GO",
  add_access_point: "Add New Access Point",
  edit_access_point: "Edit Access Point",
  label_maxUnit: "Max Units",
  error_MaxUnit_required: "Max Unit is required",
  error_MaxUnit_not_negative_number: "Max Unit not pass in negative number",
  no_data_found_error: "No Access Point Found",
  no_available_flex_forms: "No Available Flex Forms Found",
  calendar_captured: "Calendar successfully captured",
  add_response_message: "Successfully Add Access Point",
  edit_response_message: "Successfully Edit Access Point",
  delete_response_message: "Successfully Delete Access Point",
  resource_heading_text: "Resources",
  vehicles_heading_text: "Vehicles",
  resource_text: "Resource",
  accesspoint_text: "Access Points",
  maxqty_text: "Max Quantity",
  active_text: "Active",
  account_success_message: "Account Details are Updated",
  error_Phone_required: "Phone number is required",
  error_dateformat_required: "Date Format is required",
  error_language_required: "Language  is required",
  error_timeformat_invalid: "Date Format is required",
  error_startday_required: "Start Day is required",
  error_Phone_invalid: "Hmm... that phone number doesn't look valid",
  error_email_invalid: "Hmm... that Email doesn't look valid",
  apply_filter: "Apply Filters",
  login_again: "Token Expired Login Again",
  something_went_wrong: "Something Went wrong",
  booking_created: "Booking Created",
  thank_you_for_booking_your_delivery_into:
    "Thank you for booking your delivery at",
  description:
    "Please note your delivery may be subject to approval by the site team and is only confirmed when the booking status appears as SCHEDULED. If your booking is currently showing as FOR APPROVAL, a member of the site team will review your booking and you will be notified by email once it has been approved",
  summary: "Summary",
  booking_id: "Booking ID",
  date: "Date",
  go_to_list_view: "Go to list view",
  make_another_booking: "Make Another Booking",
  take_a_note: "Please take note of the following site rules:",
  ppe_worn: "PPE is to be worn at all times while on site",
  vehicle_not_allowed:
    "Vehicles will not be allowed into the the site if contractors do not have a high visibility vests",
  priority:
    "Priority will be given to vehicles arriving on time for their allotted slot",
  see_all: "Show more",
  see_less: "Show less",
  scheduled: "Scheduled",
  repeats: "Repeats",
  anticipated_no_check_ins: "Anticipated No. Check-ins",
  start_date_is_required: "Please Select Start Date",
  end_date_is_required: "Please Select End Date",
  select_day: "Please Select Day",
  date_is_required: "Please Select Date",
  choose_date: "Choose date",
  choose_start_date: "Choose Start date",
  choose_end_date: "Choose End date",
  repeat_every: "Repeat every",
  repeat_type_required: "Repeat type is required",
  repeat_on: "Repeat on",
  month_week_required: "Month week is required",
  month_day_required: "Month days is required",
  check_in: "Check In",
  refuse_entry: "Refuse Entry",
  checkout_desc:
    "Booking complete, No more actions can be taken on this booking",
  booking_refused_desc:
    "Booking refused, No more actions can be taken on this booking",
  booking_decline_desc:
    "Booking declined, No more actions can be taken on this booking",
  cancel_desc:
    "Booking cancelled, No more actions can be taken on this booking",
  approve: "Approve",
  reschedule: "Reschedule",
  decline: "Decline",
  drag_and_drop: "Drag and drop your files or",
  browse_file: "BROWSE FILES",
  welcome_to: "Welcome To",
  project_management: "Project Management",
  project_details: "Project Details",
  availibility: "Availibility",
  my_organization: "My Organization",
  notification_preferences: "Notification Preferences",
  language: "Language",
  date_format: "Date Format",
  time_format: "Time Format",
  users: "Users",
  no_resource_available_for_access_point: "No Resource Available",
  project_setting: "Project Settings",
  procore_connection_desc:
    "Connect Voyage Control to your Procore project to unlock a number of additional features including logging delivery information to the Procore Daily Log.",
  edit_integration: "Edit Integration",
  procore_login_info_message:
    "You will need your Procore login credentials, have installed the Voyage Control app from the Procore marketplace. You may also need to enable some additional permissions, these can be found on the Voyage Control page in the Procore marketplace.",
  connect: "Connect",
  disconnect: "Disconnect",
  disable: "Disable",
  procore_company_desc:
    "You will need your Procore company and project ID’s, and to have installed the Voyage  app  the Procore marketplace. You may need to enable some additional permissions, these can be found on the Voyage Control page in the Procore marketplace.",
  project: "Project",
  confirm_disconnect_message:
    "Are you sure you want to disconnect from Procore",
  disconnecting_cause_feature_not_working:
    "Disconnecting from Procore may stop some features from working.",
  additional_features: "Additional Features",
  core_features: "Core Features",
  pro_features: "Pro Features",
  automatic_approvals: "Automatic Approvals",
  automatic_approvals_details:
    "All bookings will be automatically approved immedietly after they are made.",
  log_action_to_daily_log: "Log Actions to Procore Daily Log",
  log_action_to_daily_log_detail:
    "Each time a action is made on a booking, it will be logged to the Procore daily log. This feature requires setup of the Procore integration, which can be done on the integrations tab.",
  edit_actions: "Configure",
  choose_booking_action: "Choose Booking Actions",
  choose_booking_action_detail:
    "Each time one of the selected actions is made on a booking, it will be logged to the Procore daily delivery log.",
  Edited: "Edited",
  Moved: "Moved",
  are_you_sure_disconnect_daily_log:
    "Are you sure you want to disable the Procore daily log",
  are_you_sure_connect_daily_log:
    "This feature requires the Procore integration to be connected before it can be activated. Do you want to go to integrations",
  are_you_sure_disconnect_daily_log_detail:
    "After disabling this feature booking actions will no longer be recorded in the Procore daily delivery log. Any previous logged action will remain in Procore.",
  are_you_sure_connect_daily_log_detail:
    "Connect with Procore to activate the Procore Daily Log feature.",
  go_to_integrations: "Go to Integrations",
  organization_name: "Organization Name",
  upload_logo: "Upload Logo",
  click_to_browse: "Click to browse or",
  drag_n_drop_your_file: "drag and drop your files",
  pending_approval: "Pending Approval",
  approved: "Approved",
  checked_in_multi_vehicle: "Checked In (Multi-Vehicle)",
  refused_multi_vehicle: "Refused (Multi-Vehicle)",
  checked_out_multi_vehicle: "Checked Out (Multi-Vehicle)",
  pending_approval_detail:
    "Notification that your booking has been made and is awaiting approval by the project team.",
  booking_approved_detail:
    "Notification that your booking has been approved and is confirmed for the scheduled date and time.",
  booking_declined_detail: "Notification that your booking has  been declined.",
  booking_checked_in_detail:
    "Notification that your booking has arrived at the project site and has been checked in by the project team.",
  booking_refused_detail:
    "Notification that your booking has been refused entry to the project site.",
  booking_checked_out_detail:
    "Notification that your booking has departed the project site and has been checked out by the project team.",
  booking_checked_in_multi_vehicle_detail:
    "Notification that a vehicle, part of a multi-vehicle booking, has arrived at the project site and has been checked in by the project team. You will receive a notification each time a vehicle that is part of a multi-vehicle booking is checked in.",
  booking_refused_multi_vehicle_detail:
    "Notification that a vehicle, part of a multi-vehicle booking, has been refused entry to the project site. You will receive a notification each time a vehicle that is part of a multi-vehicle booking is refused.",
  booking_checked_out_multi_vehicle_detail:
    "Notification that a vehicle, that is part of a multi-vehicle booking, has departed the project site and has been checked out by the project team. You will receive a notification each time a vehicle that is part of a multi-vehicle booking is checked out.",
  booking_cancelled_detail:
    "Notification that your booking has been cancelled.",
  booking_edited_detail: "Notification that your booking has been edited.",
  booking_moved_detail:
    "Notification that your booking has been moved to a new date or time.",
  new_user_registered: "New User Registered",
  required_approval: "Requires Approval",
  pending_approval_detail_my_org:
    "Notification that a booking made by someone in your organization is awaiting approval by the project team.",
  booking_approved_detail_my_org:
    "Notification that a booking made by someone in your organization has been approved and is confirmed for the scheduled date and time.",
  booking_declined_detail_my_org:
    "Notification that a booking made by someone in your organisation has  been declined.",
  booking_checked_in_detail_my_org:
    "Notification that a booking made by someone in your organisation has arrived at the project site and has been checked in by the project team.",
  booking_refused_detail_my_org:
    "Notification that a booking made by someone in your organisation has been refused entry to the project site.",
  booking_checked_out_detail_my_org:
    "Notification that a booking made by someone in your organisation has departed the project site and has been checked out by the project team.",
  booking_checked_in_multi_vehicle_detail_my_org:
    "Notification that a vehicle, part of a multi-vehicle booking, made by someone in your organisation, has arrived at the project site and has been checked in by the project team. You will receive a notification each time a vehicle that is part of a multi-vehicle booking is checked in.",
  booking_refused_multi_vehicle_detail_my_org:
    "Notification that a vehicle, part of a multi-vehicle booking, made by someone in your organisation, has been refused entry to the project site. You will receive a notification each time a vehicle that is part of a multi-vehicle booking is refused.",
  booking_checked_out_multi_vehicle_detail_my_org:
    "Notification that a vehicle, part of a multi-vehicle booking, made by someone in your organisation, has departed the project site and has been checked out by the project team. You will receive a notification each time a vehicle that is part of a multi-vehicle booking is checked out.",
  booking_cancelled_detail_my_org:
    "Notification that a booking made by someone in your organisation has been cancelled.",
  booking_edited_detail_my_org:
    "Notification that a booking made by someone in your organisation has been edited.",
  booking_moved_detail_my_org:
    "Notification that a booking made by someone in your organisation has been moved to a new date or time.",
  pending_approval_detail_all_proj: "",
  pending_requires_detail_all_proj:
    "Notification that a booking is awaiting approval by you or another member of the project team.",
  booking_approved_detail_all_proj:
    "Notification that a booking has been approved and is confirmed for the scheduled date and time.",
  booking_declined_detail_all_proj:
    "Notification that a booking has  been declined.",
  booking_checked_in_detail_all_proj:
    "Notification that a booking has arrived at the project site and has been checked in by you or another member of the project team.",
  booking_refused_detail_all_proj:
    "Notification that a booking has been refused entry to the project site.",
  booking_checked_out_detail_all_proj:
    "Notification that a booking has departed the project site and has been checked out by you or another member of the project team.",
  booking_checked_in_multi_vehicle_detail_all_proj:
    "Notification that a vehicle, part of a multi-vehicle booking, has arrived at the project site. You will receive a notification each time a vehicle that is part of a multi-vehicle booking is checked in.",
  booking_refused_multi_vehicle_detail_all_proj:
    "Notification that a vehicle, part of a multi-vehicle booking, has been refused entry to the project site. You will receive a notification each time a vehicle that is part of a multi-vehicle booking is refused.",
  booking_checked_out_multi_vehicle_detail_all_proj:
    "Notification that a vehicle, part of a multi-vehicle booking, has departed the project site. You will receive a notification each time a vehicle that is part of a multi-vehicle booking is checked out.",
  booking_cancelled_detail_all_proj:
    "Notification that a booking has been cancelled.",
  booking_edited_detail_all_proj:
    "Notification that a booking has been edited.",
  booking_moved_detail_all_proj:
    "Notification that a booking has been moved to a new date or time.",
  new_user_registered_detail:
    "Notification that an invited user has completed registration and can access the project. ",
  booking_ref: "Booking Ref",
  arrival_date: "Arrival Date",
  arrival_time: "Arrival Time",
  departure_time: "Departure Time",
  contact_company: "Contact Company",
  "For Approval": "FOR APPROVAL",
  "Checked out": "Checked out",
  "Checked In": "Checked In",
  creator_company: "Creator Company",
  recurring: "Recurring",
  delivery: "Delivery",
  "multi-vehicle": "Multi-Vehicle",
  "resource-only": "Resource-Only",

  my_bookings: "My Bookings",
  my_org_bookings: "My Orgs Bookings",
  all_project_bookings: "All Project Bookings",
  Active: "Active",
  Inactive: "Inactive",
  Invited: "Invited",
  Restricted: "Restricted",
  Blocked: "Blocked",
  verified_domains: "Verified Domains",
  email_domain: "Email Domain",
  unverified_organization_info_message:
    "Your organisation is unverified. We can only verify the organisations of users who signup using their company email domain.",
  verified_organization_info_message:
    "This organisation is verified. We have confirmed the organisations email domain and users in this organisation are able to take advantage of additional features.",
  project_information: "Project Information",
  site: "Site",
  availability: "Availability",
  unlimited: "Unlimited",
  Sunday: "Sunday",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sun: "Sun",
  Mon: "Mon",
  Tue: "Tue",
  Wed: "Wed",
  Thu: "Thu",
  Fri: "Fri",
  Sat: "Sat",
  settings: "Settings",
  integrations: "Integrations",
  select: "Select",
  from: "From",
  booking_information: "Booking Information",
  activity: "Activity",
  notes: "Notes",

  site_detail_tooltip:
    "These details will be used to determine the sites location and time zone. They will also appear on the project homepage.",
  project_address_tooltip:
    "Enter the project address or select a point on the map.",
  home_screen_message_tooltip:
    "Provide users with information about the site and to keep them updated on any changes. This message will be displayed on the homepage.",
  project_logo_tooltip:
    "Upload your project logo. This will be displayed in various places within the app, including on the project homepage.",
  project_banner_tooltip:
    "Add a banner image to the project homepage to give it a more personal touch.",
  project_information_contact_tooltip:
    "Add contact information for key people on the project. The contacts added here are displayed on the project homepage.",
  information_accesspoint_tooltip:
    "These are the points on site where a delivery can be made. You can give it a name and capacity. All Sites must have at least one access point",
  max_unit_tooltip:
    "This is the maximum number of bookings that can be made for the access point in a single time slot.",
  information_resource_tooltip:
    "Resources are the bookable equipment available on the site. For example, cranes, hoists and forklifts.",
  information_vehicle_tooltip:
    "In this section of the site you can indicate the availability and ordering of vehicles for creating bookings on your project. Each project must contain at least one available vehicle type.",
  resource_access_point_tooltip:
    "Select the access points this resource is available at. For example, a crane may only be able to make picks at a certain access point",
  resource_max_quantity_tooltip:
    "This is the maximum number of bookings that can be made for this resource in a single time slot.",
  site_hours_of_operation_tooltip:
    "Set the general opening days and times the site is open to take deliveries. Checking the ‘All day’ box signifies the site is open 24 hours on that day.",
  site_breaks_tooltip:
    "Use breaks to restrict bookings for short periods within the sites operational hours. Breaks will reoccur weekly on the selected days.",
  site_closure_tooltip:
    "Use site closures to restrict bookings on specific dates and access points. For example, a public holiday.",
  site_booking_creation_message_tooltip:
    "Provide users with extra information about the booking process and how they should proceed. This message will be displayed in the booking creation process.",
  site_plan_tooltip:
    "Upload a project site plan so the users can better plan their deliveries. The site play will be displayed in the booking creation process.",
  resource_operating_time_tooltip:
    "Set the general opening days and times each resource is available to book. Checking the ‘All day’ box signifies the resource is available 24 hours on the selected day.",
  resource_closure_tooltip:
    "Use resource closures to restrict bookings for resources on specific dates. For example, a public holiday.",
  booking_duration_tooltip:
    "Adjust the duration for the time needed on site. For a multi-vehicle booking, the duration should be the total time needed for all vehicles.",
  duration_hrs: "Duration (hrs)",
  booking_contact_tooltip:
    "Enter the contact information for the person responsible for the booking.",
  organization_logo_tooltip: "Upload your organizations logo.",
  verified_domains_tooltip:
    "When a user is invited with an email address ending with one of these verified domains, they will be automatically suggested to join this organisation.",
  delivery_information_message:
    "A standard, one time visit to site e.g. delivering some materials. A resource can be added to this booking type.",
  recurring_information_message:
    "A regularly occuring visit to site e.g. weekly refuse collection. A resource can be added to this booking type.",
  multivehicle_information_message:
    "A booking where multiple vehicles will arrive at the site e.g. concrete delivery. A resource can be added to this booking type.",
  resource_only_information_message:
    "A booking for use of a site resource only, no vehicle access will be booked using this booking type.",
  booking_date: "Booking Date",
  booking_time: "Booking Time",
  Weekly: "Weekly",
  Monthly: "Monthly",
  First: "First",
  Last: "Last",
  a_password_must_contain: "A password must contain:",
  organization_updated_successfully:
    "Successfully Updated Organization Details",
  "This field is required.": "This field is required",
  contact_edit_successfully: "Successfully Edited Contact",
  please_check_your_email: "Please check your email",
  for_reset_password: "for reset password",
  disable_procore_info_tooltip:
    "This ‘Pro’ feature is not enabled for this project. If you are interested in this feature, please talk to your Account Manager or contact the sales team at sales@voyagecontrol.com.",
  procore_company_and_project_updated:
    "Successfully Updated Procore Company and Project",
  daily_log_action_updated_for_booking_status:
    "Successfully Updated Daily log Actions for booking status",
  disconnected_daily_log_action: "Daily log action is disconnected",
  disconnected_procore: "Disconnect from procore",
  connected_daily_log_action: "Daily log action is connected",
  invite_from_procore: "Invite from Procore",
  invite_canceled: "User Invite Canceled",
  invite_resend: "User Invite was resend",
  please_select_user: "Please Select at least one user",
  procore_unauthorize: "Authorization unsuccessful",
  procore_authorize: "Authorization successful",
  error_procore_redirect:
    "Integrations is not active for this project. Please, contact your administrator",
  selected: "Selected",
  print: "Print",
  column_config: "Column Config",
  column_config_new: "New Column Config",
  column_config_name: "New Layout Name",
  column_config_are_you_sure:
    "Are you sure you want to delete this custom view?",
  column_config_delete: "Delete View",
  set_default: "Set as default",
  set_default_tooltip: "Available only for project admin",
  column_config_created: "Successfully Created Column Config",
  column_config_updated: "Successfully Updated Column Config",
  column_config_deleted: "Successfully Deleted Column Config",
  column_config_cannot_be_changed: "This config cannot be changed",
  new_layout: "New Layout",
  select_column: "Select Columns",
  column_order: "Column Order",
  by_access_point: "By Access Point",
  by_resource: "By Resource",
  cant_move_booking_across: "You can't move booking across the ",
  accesspoint: "AccessPoints",
  project_settings_updated: "Successfully Updated Project Settings",
  invite_link_cancelled: "Invite Link Cancelled",
  invite_link_cancelled_desc:
    "Your email invitation link has been withdrawn. This might be because it was sent in error or you no longer require access to this project. If you think this is incorrect you need to request a new invite from the project team in order to join this project.",
  all_resource: "All Resource",
  only_available: "Only Available",
  only_booked: "Only Booked",
  // Dynamic Text Needs to be Translated as it is
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  Expired: "Expired",
  label_M: "M",
  label_TUE: "T",
  label_W: "W",
  label_THU: "T",
  label_F: "F",
  label_SA: "S",
  label_SU: "S",
  hour: "Hour",
  minute: "Minute",
  admin: "Admin",
  check_in_form_not_complete: "Checkin form not complete",
  check_out_form_not_complete: "Checkout form not complete",
  // +++++++++++++++++++++ BACKEND TEXT ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

  "Project Admin": "Project Admin",
  "Project Manager": "Project Manager",
  "Project Owner": "Project Owner",
  "Project User": "Project User",
  "Security Guard": "Security Guard",
  "View Only": "View Only",
  "Project Senior User": "Project Senior User",
  Van: "Van",
  "18 Wheeler": "18 Wheeler",
  "Box Truck": "Box Truck",
  "Car + Trailer": "Car + Trailer",
  Car: "Car",
  "Concrete Truck": "Concrete Truck",
  "Dump Truck": "Dump Truck",
  "Flatbed Truck": "Flatbed Truck",
  "Garbage Truck": "Garbage Truck",
  "Mobile Crane": "Mobile Crane",
  Other: "Other",
  "Pickup Truck": "Pickup Truck",
  "Pump Truck": "Pump Truck",
  Tanker: "Tanker",
  "Raised Floor Trailer": "Raised Floor Trailer",
  "Low Floor Trailer": "Low Floor Trailer",
  "15t Flat Truck": "15t Flat Truck",
  "15t Unic Truck": "15t Unic Truck",
  "10t Flat Truck": "10t Flat Truck",
  "10t Unic Truck": "10t Unic Truck",
  "10t dump truck": "10t Dump Truck",
  "10t Dump Truck": "10t Dump Truck",
  "10t arm roll": "10t Arm Roll",
  "10t Arm Roll": "10t Arm Roll",
  "4t arm roll": "4t Arm Roll",
  "4t Arm Roll": "4t Arm Roll",
  "8t Flat Truck": "8t Flat Truck",
  "8t Unic Truck": "8t Unic Truck",
  "4t Flat Truck": "4t Flat Truck",
  "4t Unic Truck": "4t Unic Truck",
  "2t Flat Truck": "2t Flat Truck",
  "2t_Flat_Truck": "2t Flat Truck",

  "Concrete Mixer Truck": "Concrete Mixer Truck",
  "16t Rough Terrain-Crane": "16t Rough Terrain-Crane",
  "25t Rough Terrain-Crane": "25t Rough Terrain-Crane",
  "50t Rough Terrain-Crane": "50t Rough Terrain-Crane",
  "65t Rough Terrain-Crane": "65t Rough Terrain-Crane",

  Auth_err_email_not_found:
    "An account with this email address does not exist or is inactive. Please contact the project administrator to request an invite in order to sign up.",
  Auth_err_invalid_token: "Invalid token",
  Auth_err_missing_credentials: "Authentication credentials were not provided.",
  Auth_err_no_active_account: "Wrong Username or password",
  Auth_err_no_active_user: "Not found active user with this email address",
  Auth_err_project_already_exists: "Project already exists in this region",
  Auth_err_similar_password: "Similar password",
  Auth_err_validating_old_password: "Error validating old password",
  Auth_msg_password_reset: "Password reset successfully.",
  Auth_msg_user_removed_from_project: "User Removed from project successfully.",
  Err_all_access_point_slots_booked: "All slots for access-points are booked",
  Err_all_resource_slots_booked: "All slots for resource are booked",
  Err_all_vehicles_cleared: "No more vehicles left. All are cleared",
  Err_answer_option_invalid: "Provided option is not valid",
  Err_attachment_booking_mismatch: "This attachment is for another booking",
  Err_body_must_be_list: "Body must be a list of objects",
  Err_booking_can_not_be_edited_in_current_state:
    "Booking can not be edited in current state.",
  Err_booking_can_not_be_rescheduled_in_current_state:
    "Booking can not be rescheduled in current state.",
  Err_booking_date_in_past: "Booking date should not be in the past",
  Err_booking_does_not_exist: "Booking does not exist",
  Err_booking_status_change: "Cant perform action on booking in current status",
  Err_booking_type_requires_access_point:
    "Access point is required for this booking type",
  Err_can_not_reschedule_recurring: "Cannot reschedule recurring booking",
  Err_cant_assign_owner_role: "Cant assign owner project role to another user",
  Err_cant_book_after_advanced_date: "Cant book after advanced date",
  Err_cant_book_before_advanced_date: "Cant book before advanced date",
  Err_cant_change_own_status_but_cancel:
    "You can not change your booking status, you can only cancel it",
  Err_cant_move_booking: "Booking cant be moved",
  Err_comment_required: "Comment is mandatory",
  Err_company_missing: "Company param Missing",
  Err_contact_required: "Contact is mandatory",
  Err_date_range_not_match_booking_numbers:
    "Range of dates does not match the booking numbers",
  Err_date_required: "Date is required",
  Err_daterange_must_have_two_values: "daterange must have two values",
  Err_does_not_exist: "Does not exist",
  Err_domain_not_allowed: "Your domain is not allowed",
  Err_domains_already_registered:
    "Some of these domains are already registered by another organization",
  Err_duplicate_break:
    "There is an matching break, you might want to update that",
  Err_duplicate_closure:
    "There is an matching closure, you might want to update that",
  Err_duplicate_organization_name:
    "An Organization with the same name already exists! Please choose a different name for your Organization.",
  Err_duplicate_site_name: "Site with the same name already exists",
  Err_duplicate_user_email: "User with same email exists.",
  Err_email_body_not_defined: "Body must be defined",
  Err_email_not_found: "Email not found",
  Err_email_subject_not_defined: "Subject must be defined",
  Err_email_template_not_defined: "Template must be defined",
  Err_file_ref_invalid: "File Ref provided for question is invalid",
  Err_incomplete_onboarding: "Incomplete Onboarding",
  Err_incorrect_user_role: "User does not have correct role access.",
  Err_integration_config_not_found: "Integration Config object missing",
  Err_integration_disconnect_handler_not_defined:
    "Integration Disconnect handler not defined in `IntegrationEnableHandlers` class",
  Err_integration_handler_not_defined:
    "Integration handler not defined in `IntegrationEnableHandlers` class",
  Err_integration_not_found: "Integration not enabled",
  Err_invalid_access_point: "Invalid access point",
  Err_invalid_access_point_on_resource:
    "Invalid Access point on selected resource",
  Err_invalid_booking_status: "Booking status is not a valid choice",
  Err_invalid_booking_type: "Invalid choice of booking type",
  Err_invalid_data: "Invalid data",
  Err_invalid_project: "Invalid project",
  Err_invalid_project_role: "Invalid project role",
  Err_invalid_project_status:
    "Invalid status choice, one of [pre-live, active, paused. finished]",
  Err_invalid_region: "Invalid region",
  Err_invalid_role: "Invalid role",
  Err_invalid_site: "Invalid site",
  Err_misconfigured_region: "DB Region is misconfigured",
  Err_missing_auth_credentials: "Authentication credentials were not provided.",
  Err_missing_recurring_booking_options:
    "One of [repeat_option, repeat_weekdays, repeat_monthly_option] is missing",
  Err_missing_some_required_fields: "Missing some required fields",
  Err_multi_vehicle_booking_not_supported:
    "Project does not support Multiple vehicle bookings",
  Err_must_be_access_point: "Obj must be a access point",
  Err_must_be_resource: "Obj must be a resource",
  Err_no_access_to_booking_list: "No access to booking list",
  Err_no_booking_dates: "No dates found to book",
  Err_no_slots_for_access_point_and_Resource:
    "No slots for this access-points and resource",
  Err_not_multi_vehicle_booking: "Not a multi vehicle booking",
  Err_not_part_of_any_organization_or_role:
    "Not part of any organization or role",
  Err_null_project_role: "Expected project role but got null value",
  Err_num_vehicles_required:
    "number_of_vehicles is required for Multi vehicle type",
  Err_organization_and_role_mismatch:
    "Organization and Organization Role does not match",
  Err_organization_domain_exists:
    "An Organization matching this email domain already exists.",
  Err_organization_not_found: "Organization Does Not Exists",
  Err_owner_email_required: "Owner Email is required",
  Err_permission_denied: "You are not allowed to perform this operation",
  Err_preference_not_exist: "Preference does not exist",
  Err_procore_auth_code_missing: "Procore authentication code is missing",
  Err_project_name_already_exists: "Project name already exists",
  Err_project_not_active_deleted_or_status_not_allowed:
    "Project not active or deleted",
  Err_project_not_authenticated_for_integration:
    "Project is not authenticated for this integration",
  Err_project_user_does_not_exist: "Project user does not exist",
  Err_provide_one_of_access_point_or_resource:
    "Provide one of access_point or resource but not both",
  Err_question_not_exist: "Question does not exist",
  Err_recurring_booking_not_supported:
    "Project does not support Recurring bookings",
  Err_repeat_monthly_option_missing:
    "repeat_monthly_option is missing, one of [FIRST, LAST]",
  Err_repeat_option_missing: "repeat_option is missing, one of weekly, monthly",
  Err_repeat_weekdays_expects_single_day:
    "repeat_weekdays for monthly option expects only one single day, not  multiple",
  Err_repeat_weekdays_missing:
    "repeat_weekdays is missing, one of [Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday]",
  Err_require_one_of_access_point_or_resource:
    "Provide one of access_point or resource",
  Err_required_project_param: "Project param is required",
  Err_required_region_or_project: "One of region or project is required",
  Err_reserved_slot_expired_or_used:
    "Reserved slot expired, or its already been used",
  Err_resource_closure_not_found: "Resource Closure not found",
  Err_resource_not_operational: "Resource is not set for operation",
  Err_resource_only_booking_not_supported:
    "Project does not support Resource only bookings",
  Err_resource_required: "Resource is mandatory",
  Err_resource_required_for_resource_only:
    "Trying to create a resource only booking but the specified Slot did not reserve any resource",
  Err_resource_site_mismatch: "Resource and Site Does Not Match",
  Err_resource_timeing_not_found: "Resource Timing not found",
  Err_site_access_point_mismatch: "Access point does not belong to this site",
  Err_site_does_not_exists: "Site does not exist",
  Err_site_resource_mismatch: "Resource does not belong to this site",
  Err_slot_not_available: "Slot not available, already taken",
  Err_slot_not_reserved_for_booking_type:
    "Slot chosen was not reserved for this booking type",
  Err_slot_uuid_not_found: "No slot reserved matching this UUID",
  Err_some_feature_actions_are_not_supported:
    "Some feature actions chosen are not supported",
  Err_start_date_greater_than_end: "End date should be after start date",
  Err_start_end_date_required: "Both Start and End dates are required",
  Err_start_time_greater_than_end: "Start Time Should be before End Time",
  Err_unknown_response_from_external_api:
    "Unknown response from external integration API",
  Err_unsupported_export_format: "Unsupported export type",
  Err_user_already_onboarded:
    "User already onboarded or did not receive an invitation.",
  Err_user_does_not_exist: "User does not exist",
  Err_user_has_no_access: "User does not have access",
  Err_user_inactive: "User is inactive",
  Err_user_invalid_token: "Token contained no recognizable user identification",
  Err_user_not_found: "User not found",
  Err_user_project_role_not_exists: "Project role for user does not exist",
  Err_username_not_set: "The given username must be set",
  Err_uuid_reserved_another_slot: "UUID was used to reserve another slot",
  Err_uuid_used_create_another: "UUID has been used, create another uuid",
  Err_vehicle_not_exist: "vehicle item not exist",
  Err_vehicle_type_required: "Vehicle type is mandatory",
  Msg_booking_field_created_success:
    "Project Booking Field Created/Updated successfully.",
  Msg_user_remove_from_project: "User removed from project",
  Msg_users_invited_success: "Users have been invited",
  "Not Found": "Not Found",
  Status: "Status",
  Resource: "Resource",
  "Booking Ref": "Booking Ref",
  "Arrival Date": "Arrival Date",
  "Arrival Time": "Arrival Time",
  "Departure Time": "Departure Time",
  "Access Point": "Access Point",
  "Vehicle type": "Vehicle type",
  "Contact Company": "Contact Company",
  reinstate_booking: "Undo",
  reinstate_booking_are_you_sure:
    "Are you sure you want to reinstate this booking?",
};
export default Translation;
