import ActionType from "../../Actions/ActionType";
import { bookingListAction } from "../../Actions/BookingList/BookingListAction";
let initialState = {
  filterData: {},
};

const bookingList = (
  state: object = initialState,
  action: bookingListAction
) => {
  switch (action.type) {
    case ActionType.SET_BOOKING_LIST_FILTER:
      initialState.filterData = action.data;
      return {
        ...state,
        filterData: action.data,
      };
    default:
      return initialState;
  }
};

export default bookingList;
