import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// component
import AppRoutes from "./AppRoutes";
import Toaster from "./Components/UI/Toaster";

// redux
import { setLanguage } from "./Store/Actions/langaugeActionCreator";

// helper
import "./Language/i18n";

// css
import "./Assets/css/bootstrap.min.css";
import "./Assets/css/feather.min.css";
import "./Assets/css/style.css";
import "./Assets/css/custom.css";
import { getLocalStorage, setSession } from "Network/ApiService";
import { ls_vars } from "Library/Constants";

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = useSelector((state: any) => state?.language?.language);

  useEffect(() => {
    if (language !== undefined) {
      dispatch(setLanguage(language));
      i18n.changeLanguage(language);
    } else {
      dispatch(setLanguage("en"));
      i18n.changeLanguage("en");
    }
  }, [language]);

  const token = getLocalStorage(ls_vars.ACCESS_TOKEN);

  useEffect(() => {
    if (token) setSession(token);
  }, [token]);

  return (
    <div>
      <AppRoutes />
      <Toaster />
    </div>
  );
}

export default App;
