import ActionType from "../../Actions/ActionType";
import {
  BookingColumns,
  BookingColumn,
  BookingFields,
  DefaultState,
} from "../../Actions/BookingConfig/BookingColumnAction";

let initialState = {
  fieldsList: [],
  columnConfigList: [],
  activeColumnConfig: null,
};

const setColumns = (
  state: DefaultState = initialState,
  action: BookingColumns | BookingColumn | BookingFields
) => {
  switch (action.type) {
    case ActionType.SET_BOOKING_FIELDS:
      return {
        ...state,
        fieldsList: action.data,
      };
    case ActionType.SET_BOOKING_COLUMN:
      return {
        ...state,
        activeColumnConfig: action.data,
      };
    case ActionType.SET_BOOKING_COLUMNS:
      return {
        ...state,
        columnConfigList: action.data.list,
        ...(action.data.current
          ? { activeColumnConfig: action.data.current }
          : {}),
      };
    default:
      return state;
  }
};

export default setColumns;
