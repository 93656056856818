import { createSlice } from "@reduxjs/toolkit";
import { get } from "Network/ApiService";
import { ApiUrl } from "Network/ApiUrl";
import { t } from "i18next";
import toast from "react-hot-toast";

export interface SiteContact {
  id: number;
  site: {
    id: number;
    name: string;
  };
  companyName: any;
  email: any;
  name: string;
  position: string;
  phone: string;
}

export interface ProjectSite {
  id: number;
  name: string;
  project: number;
  isActive: boolean;
  createdAt: string;
  modifiedAt: string;
  plan: string;
  bookingMessage: string;
  contacts: SiteContact[];
}

export interface ProjectDetails {
  name: string;
  address: string;
  region: number;
  logoImage: string;
  bannerImage: string;
  message: string;
  timezone: string;
  ref: string;
  slug: string;
}

interface InitialStateType {
  isLoading: boolean;
  projectDetails: ProjectDetails | null;
  projectSites: ProjectSite[];
  error: any;
}

const initialState: InitialStateType = {
  isLoading: false,
  projectSites: [],
  projectDetails: null,
  error: null,
};

const slice = createSlice({
  name: "project",
  initialState,
  reducers: {
    onLoading(state) {
      state.isLoading = true;
    },
    setProjectDetails(state, action) {
      state.projectDetails = action.payload;
    },
    setProjectSites(state, action) {
      state.projectSites = action.payload;
      state.isLoading = false;
    },

    onError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const getSiteInfoAction = (projectRef: string) => (dispatch: any) => {
  dispatch(slice.actions.onLoading());
  get(`${ApiUrl.siteManagement.getSiteDetail}?project=${projectRef}`)
    .then(({ data }: any) => {
      dispatch(slice.actions.setProjectDetails(data));
    })
    .then(async () => {
      await get(
        `${ApiUrl.siteManagement.getSiteList}?project=${projectRef}`
      ).then(async ({ data }: any) => {
        const sitesList: ProjectSite[] = [...data.results];
        for (const site of sitesList) {
          const contactsRes = await get(
            `${ApiUrl.siteManagement.getSiteList}${site.id}/contacts/?project=${projectRef}`
          );
          site.contacts = contactsRes?.data?.results;
        }
        dispatch(slice.actions.setProjectSites(sitesList));
      });
    })
    .catch((err) => {
      const errorMsg =
        err?.response?.data?.error || "error_something_went_wrong";
      toast.error(t(`Translation.${errorMsg}`));
      dispatch(slice.actions.onError(err?.response?.data));
    });
};

export default slice.reducer;
