import { combineReducers } from "redux";
import language from "./langauge";
import auth from "./AuthModule/auth";
import inviteUser from "./InviteUserModule/inviteUser";
import sidebarDetail from "./Comman/sidebar";
import loader from "./Loader/loader";
import user from "./UserManagment/user";
import booking from "./BookingModule/booking";
import bookingList from "./BookingListModule/BookingList";
import setSiteID from "./ProjectManageMent/SiteIDetails";
import setProject from "./ProjectManageMent/ProjectSetting";
import bookingDetailReducer from "./BookingDetail/bookingDetails";
import saveProFeaturesDataReducer from "./ProFeatures/ProFeatures";
import setColumns from "./BookingConfig/bookingColumn";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import projectReducer from "Store/Slices/projectReducer";
const rootReducer = combineReducers({
  language,
  auth,
  inviteUser,
  sidebarDetail,
  loader,
  user,
  booking,
  bookingList,
  setSiteID,
  setProject,
  bookingDetailReducer,
  saveProFeaturesDataReducer,
  setColumns,
  projectReducer,
});

export default rootReducer;

export type State = ReturnType<typeof rootReducer>;

export const useAppSelector: TypedUseSelectorHook<State> = useSelector;
