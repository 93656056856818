import axios from "axios";
import { SsoApiUrl } from "./ApiUrl";
import { ls_vars } from "Library/Constants";

const timeout = process.env.NODE_ENV === "development" ? 300000 : 120000;

const apiClient = axios.create({
  timeout,
  headers: { "Content-Type": "application/json", Accept: "*/*" },
  withCredentials: false,
  responseType: "json",
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    switch (error.response.status) {
      case 401:
        if (error.response.data?.code === "incomplete_onboarding") {
          return (window.location.href = SsoApiUrl.logoutPage);
        } else {
          return refreshTokenRequest().then((access) => {
            if (access) {
              return axios.request({
                ...error.config,
                headers: {
                  ...error.config.headers,
                  Authorization: `JWT ${access}`,
                },
              });
            }
          });
        }
      default:
        break;
    }

    return Promise.reject(error);
  }
);

export const setSession = (token?: string | null) => {
  if (token) {
    const JWT = `JWT ${token}`;
    apiClient.defaults.headers.common.Authorization = JWT;
  } else {
    delete apiClient.defaults.headers.common.Authorization;
  }
};

export const overSession = () => {
  localStorage.clear();
  window.location.href = SsoApiUrl.logoutPage;
};

export const refreshTokenRequest = async () => {
  const refreshLocal = getLocalStorage(ls_vars.REFRESH_TOKEN);
  if (refreshLocal) {
    return await axios
      .post(SsoApiUrl.tokenRefresh, { refresh: refreshLocal })
      .then((res) => {
        const { access } = res.data;
        if (access) {
          setSession(access);
          setLocalStorage(ls_vars.ACCESS_TOKEN, access);
          return access;
        }
      })
      .catch(overSession);
  } else {
    overSession();
    return null;
  }
};

export const setLocalStorage = (tokenName: string, tokenValue: any) => {
  localStorage.setItem(tokenName, tokenValue);
};

export const getLocalStorage = (tokenName: string) => {
  const item = localStorage.getItem(tokenName);
  try {
    return item ? JSON.parse(item) : null;
  } catch (error) {
    return localStorage.getItem(tokenName);
  }
};

const tokenHeaders = async (useToken?: string) => {
  const accessToken = getLocalStorage(ls_vars.ACCESS_TOKEN);
  const headers =
    useToken && accessToken !== null
      ? { headers: { Authorization: `JWT ${accessToken}` } }
      : {};
  return headers;
};

export const get = async (url: string, useToken: string = "true") => {
  const headers = await tokenHeaders(useToken);
  const response = await apiClient.get(url, headers);
  return response;
};

export const getFile = async (url: string) => {
  const headers = tokenHeaders("true");
  const response = await apiClient.get(url, {
    responseType: "blob",
    ...headers,
  });
  return response;
};

export const post = async (url: string, data: any, useToken?: string) => {
  const headers = await tokenHeaders(useToken);
  const response = await apiClient.post(url, data, headers);
  return response;
};
export const patch = async (url: string, data: any, useToken?: string) => {
  const headers = await tokenHeaders(useToken);
  const response = await apiClient.patch(url, data, headers);
  return response;
};
export const put = async (url: string, data: any, useToken?: string) => {
  const headers = await tokenHeaders(useToken);
  const response = await apiClient.put(url, data, headers);
  return response;
};
// Note: delete is a reserved word
export const remove = async (url: string, useToken?: string) => {
  const headers = await tokenHeaders(useToken);
  const response = await apiClient.delete(url, headers);
  return response;
};
