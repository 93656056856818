export const ls_vars = {
  ACCESS_TOKEN: "v3_access_token",
  REFRESH_TOKEN: "v3_refresh_token",
  LANGUAGE: "i18nextLng",
  REGION_ID: "v3_region_id",
  PROJECT_REF: "v3_project_ref",
  PROJECT_DATA: "v3_project_data",
  PROJECT_TIMEZONE: "v3_project_timezone",
  PROJECT_SETTINGS: "v3_project_settings",
  PROJECT_BOOKING_FIELDS: "v3_project_booking_fields",
  PROJECT_PROCORE_INTEGRATION_ID: "v3_project_procore_int_id",
  PROJECT_SITE_ID: "v3_project_site_id",
  USER_DATA: "v3_user_data",
  USER_PREFERENCES: "v3_user_preference",
  USER_IS_ADMIN: "v3_user_is_admin",
  USER_INVITE_DATA: "v3_user_invite_data",
  BOOKING_FLEX_FORMS: "v3_booking_flex_forms",
};

export const constants = {
  PROJECT_OWNER: "Project Owner",
  PROJECT_USER: "Project User",
  PROJECT_USER_SENIOR: "Project Senior User",
  PROJECT_MANAGER: "Project Manager",
  PROJECT_ADMIN: "Project Admin",
  PROJECT_GUARD: "Security Guard",
  PROJECT_VIEW_ONLY: "View Only",
  MONTHLY: "MONTHLY",
  WEEKLY: "WEEKLY",
  HOUR_24: "24 hour",
  HOUR_12: "12 hour",
  BOOKING_TYPES: "bookingTypes",
  ACCESS_POINTS: "accessPoints",
  VEHICLE_TYPES: "vehicleTypes",
  RESOURCES: "resources",
  RECIPIENT_COMPANIES: "recipientCompanies",
  CREATOR_COMPANIES: "creatorCompanies",
  STATUS: "statuses",
  DELIVERY: "Delivery",
  MULTI_VEHICLE: "Multi-Vehicle",
  RECURRING: "Recurring",
  RESOURCE_ONLY: "Resource-Only",
  GOOGLE_MAP_MARKER:
    "https://icon-library.com/images/pin-icon-png/pin-icon-png-23.jpg",
  SUPPORT_EMAIL: "support@voyagecontrol.com",
  REGION: "Region",
  INCOMPLETE_ONBOARDING: "incomplete_onboarding",
  VIEW_PROJECT_SETTINGS: "VIEW_PROJECT_SETTINGS",
  VIEW_INTEGRATIONS: "VIEW_INTEGRATIONS",
  VIEW_VEHICLE_TRACKING: "VIEW_VEHICLE_TRACKING",
  PROCORE_DAILY_LOG: "PROCORE_DAILY_LOG",
  PROCORE: "PROCORE",
  INTEGRATION_PROCORE: "INTEGRATION_PROCORE",
  PRO_FEATURE_PROCORE_DAILY_LOG: "PRO_FEATURE_PROCORE_DAILY_LOG",
  FLAG_PROJECT_SETTINGS: "FLAG_PROJECT_SETTINGS",
  ORGANIZATION_ADMIN: "admin",
  ORGANIZATION_USER: "user",
};

export const routes = {
  auth: {
    login: "/login",
    choseProject: "/project/choose",
    procoreWebhookUrl: "/authenticate/procore/callback",
  },
  profile: {
    account: "/myaccount",
    organization: "/organization",
    notification: "/notification/preference",
  },
  dashboard: {
    home: "/:project/home",
    userManagement: "/:project/dashboard/manage-user",
    siteDetails: "/:project/site-details",
    settings: "/:project/settings",
    settingsProcore: "/:project/settings/integrations/procore",
    availability: "/:project/availability",
    bookingCreate: "/:project/new-booking-form",
    bookingDetails: "/:project/booking/:bookingId",
    bookingsList: "/:project/booking",
    vehicleTracking: "/:project/vehicle-tracking",
    calendar: "/:project/calendar",
  },
  userDeleteGuidance: "/user-delete-guidance",
  dashboardNav: {
    home: (projectRef: string) => `/${projectRef}/home`,
    userManagement: (projectRef: string) =>
      `/${projectRef}/dashboard/manage-user`,
    siteDetails: (projectRef: string) => `/${projectRef}/site-details`,
    settings: (projectRef: string) => `/${projectRef}/settings`,
    settingsProcore: (projectRef: string) =>
      `/${projectRef}/settings/integrations/procore`,
    availability: (projectRef: string) => `/${projectRef}/availability`,
    bookingCreate: (projectRef: string) => `/${projectRef}/new-booking-form`,
    bookingDetails: (projectRef: string, bookingId: string) =>
      `/${projectRef}/booking/${bookingId}`,
    bookingsList: (projectRef: string) => `/${projectRef}/booking`,
    vehicleTracking: (projectRef: string) => `/${projectRef}/vehicle-tracking`,
    calendar: (projectRef: string) => `/${projectRef}/calendar`,
  },
};

export const bookingStatuses = {
  FOR_APPROVAL: 1,
  APPROVED: 2,
  CHECKED_IN: 3,
  CHECKED_OUT: 4,
  DECLINED: 5,
  CANCELLED: 6,
  REFUSED: 7,
};
