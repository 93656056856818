import { projectSetting } from "../../Actions/ProjectManageMent/SiteAction";
import ActionType from "../../Actions/ActionType";
const initialProjectSettings = {
  timeFormat: "24 hour",
};

const setProject = (
  state: object = initialProjectSettings,
  action: projectSetting
) => {
  switch (action.type) {
    case ActionType.SET_PROJECT_SETTINGS:
      return {
        ...state,
        timeFormat: action.data,
      };
    default:
      return state;
  }
};

export default setProject;
