import profileUser from "./ProfileMyAccount.svg";
import profileOrg from "./ProfileOraganization.svg";
import profileNotification from "./notification-drop.svg";
import ProfileLogout from "./ProfileLogout.svg";
import SearchIconsvg from "./Search.svg";
import plusThemeSvg from "./plus-theme.svg";
import edit from "./edit.svg";
import action from "./action.svg";
import sidebarSiteDetails from "./cmpny-setting.svg";
import sidebarAvailibity from "./clock-sidebar.svg";
import logoGreenSvg from "./logocontainer.svg";
import ProfileNotification from "./ProfileNotification.svg";
import plusIcon from "./plusGlobal.svg";
import DeclineCross from "./DeclineCross.svg";
import ApproveCheck from "./ApproveCheck.svg";
import backIcon from "./back.svg";
import deleteIcon from "./Delete.svg";
import forward from "./Forward.svg";
import cross from "./cross.svg";
import delivery from "./booking/Delivery.svg";
import multiVehicle from "./booking/MultiVehicle.svg";
import resource from "./booking/Resource.svg";
import recurring from "./booking/Recurring.svg";
import alertInfo from "./booking/alertInfo.svg";
import sitePlan from "./booking/sitePlan.svg";
import completedStep from "./booking/completedStep.svg";
import plusIconGrey from "./booking/plus.svg";
import minusIcon from "./booking/minus.svg";
import sorting from "./sorting.svg";
import dragIcon from "./Drag.svg";
import minusGreen from "./minus-green.svg";
import plusGreen from "./plus-green.svg";
import newDelete from "./new-delete-icon.svg";
import modalCloseIcon from "./modal-close-icon.svg";
import DeleteGreenIcon from "./delete-green-icon.svg";
import DateIcon from "./date-icon.svg";
import DeleteRedIcon from "./fluent_delete.svg";
import Subtract from "../images/booking/substract.svg";
import UploadIcon from "./upload-icon.svg";
import CheckIn from "./check-in.svg";
import BlueCheckIn from "./BlueCheckIn.svg";
import DeliveryIcon from "./Deliver-Icon.svg";
import RefuseIcon from "./refuse.svg";
import PDFIcon from "./PDF.svg";
import WhiteDeleteIcon from "./white-delete.svg";
import DottedIcon from "./dotted-option.svg";
import checkIconWhite from "../images/booking/Check-Icon-white.svg";
import declinedCloseButton from "../images/booking/Close X.svg";
import circleInfo from "../images/booking/Circle-Action-alert.svg";
import checkOut from "../images/Group.svg";
import rescheduleSvg from "../images/Arrow-Reply.svg";
import CSVlogo from "../images/CSVLogo.svg";
import CommentLogo from "../images/CommentLogo.svg";
import Refresh from "../images/Refresh.svg";
import RefreshWhite from "../images/RefreshWhite.svg";
import imageIcon from "../images/imageIcon.svg";
import closeIcon from "../images/CloseIcon.svg";
import tickIcon from "../images/Tick.svg";
import RedDelete from "../images/red-delete.svg";
import CalendarBack from "../images/calendar-back.svg";
import CalendarForward from "../images/calendar-forward.svg";
import HamburgerMenuIcon from "../images/Hamburger-Menu.svg";
import MobileLogo from "../images/mobile-logo.svg";
import CalendarBackArrowIcon from "../images/Custom-calendar-Back-Arrow.svg";
import CalendarForwardArrowIcon from "../images/Custom-calendar-Forward-Arrow.svg";
import CalendarDownArrow from "../images/Custom-calendar-Arrow-Down.svg";
import CalendarBackIcon from "../images/back-icon.svg";
import CalendarForwardIcon from "../images/Forward-icon.svg";
import ClockIcon from "../images/clock-icon.svg";
import ProcoreLogo from "../images/procore-logo.svg";
import ModalOutClose from "../images/modal-outside-close.svg";
import ProcoreConfirmAlert from "../images/procore-confirm-alert.svg";
import ProcoreConfirm from "../images/procore-confirm.svg";
import RedRefuse from "../images/RedRefuse.svg";
import RefuseBooking from "../images/booking/Refuse.svg";
import CheckInBooking from "../images/booking/CheckIn.svg";
import CheckOutBooking from "../images/booking/CheckOut.svg";
import CheckInWhite from "../images/booking/CheckInWhite.svg";
import RefuseGreen from "../images/booking/RefuseGreen.svg";
import CheckoutWhite from "../images/booking/CheckoutWhite.svg";
import CheckGreen from "../images/booking/GreenCheck.svg";
import RefuseApply from "../images/booking/RefuseApply.svg";
import CheckoutApply from "../images/booking/CheckoutApply.svg";
import NextArrow from "../images/NextArrow.svg";
import BackArrow from "../images/BackArrow.svg";
import Pen from "../images/Pen.svg";

// VehicleType Icons
import VanIcon from "../images/VehicleLogo/VanIcon.svg";
import ArticulatedVehicle from "../images/VehicleLogo/ArticulatedVehicle.svg";
import BoxTruck from "../images/VehicleLogo/BoxTruck.svg";
import CarTrailer from "../images/VehicleLogo/Car+Trailer.svg";
import CarIcon from "../images/VehicleLogo/CarIcon.svg";
import ConcreteTruck from "../images/VehicleLogo/ConcreteTruck.svg";
import DumpTruck from "../images/VehicleLogo/DumpTruck.svg";
import FlatBedTruck from "../images/VehicleLogo/FlatbedTruck.svg";
import GarbageTruck from "../images/VehicleLogo/GarbageTruck.svg";
import MobileCrane from "../images/VehicleLogo/MobileCrane.svg";
import Other from "../images/VehicleLogo/OtherIcon.svg";
import PickUpTruck from "../images/VehicleLogo/PickupTruck.svg";
import PumpTruck from "../images/VehicleLogo/PumpTruck.svg";
import Tanker from "../images/VehicleLogo/TankerIcon.svg";
import Car from "../images/VehicleLogo/Car.svg";
import ConcreteMixer from "../images/VehicleLogo/ConcreteMixer.svg";
import FlatTruck from "../images/VehicleLogo/FlatTruck.svg";
import LowerFloor from "../images/VehicleLogo/LowerFloor.svg";
import RaisedFloorTrailor from "../images/VehicleLogo/RaisedFloorTrailor.svg";
import RoughTarraineCrane from "../images/VehicleLogo/RoughTarraineCrane.svg";
import UnicTruck from "../images/VehicleLogo/UnicTruck.svg";

import CalenderIcon from "../images/booking/calendar.svg";
import BookingBackGroundImage from "../images/booking_details_background_image.png";
import CheckGreenIcon from "../images/right-check-green.svg";
import ProjectSettingLogo from "../images/project-settings-logo.svg";
import verifiedOrgTick from "../images/verifiedOrgTick.svg";
import yellowInfo from "../images/yellowInfo.svg";
import greenCalendarIcon from "../images/greenCalendarIcon.svg";
import greenResourceIcon from "./greenResourceIcon.svg";
import greenAccessPoint from "./greenAccessPoint.svg";
import RedCross from "./RedCross.svg";
import WhiteCheck from "./WhiteCheck.svg";
import LogoFull from "./LogoFull.svg";
import LogoMini from "./LogoMini.svg";

import CompletedCheck from "./Completed.svg";
import InCompletedCheck from "./InCompleteCheck.svg";

const images = {
  logo: require("./logo.png"),
  project1: require("./project1.png"),
  project2: require("./project2.png"),
  project3: require("./project3.png"),
  project4: require("./project4.png"),
  rightarrow: require("./right-arrow.png"),
  alert: require("./alert.png"),
  defaultBannerImage: require("./DefaultImage.jpg"),
  backarrow: require("./backarrow.png"),
  bookinglist: require("./bookinglist.png"),
  bookingcolumn: require("./bookingcolumn.png"),
  calender: require("./calender.png"),
  companySetting: require("./cmpny-setting.png"),
  collapse: require("./collapse.png"),
  done: require("./done.png"),
  download: require("./download.png"),
  form2: require("./form2.png"),
  ForwardArrow: require("./ForwardArrow.png"),
  location: require("./location.png"),
  logonew: require("./logonew.png"),
  minus: require("./minus.png"),
  notification: require("./notification.png"),
  plusTheme: require("./plus-theme.png"),
  plus: require("./Plus.png"),
  search: require("./Search.png"),
  siteManagement: require("./site-management.png"),
  users: require("./users.png"),
  logoimage: require("./banner-logo.png"),
  banner: require("./Banner.png"),
  locationIcon: require("./location.png"),
  clockicon: require("./clock.png"),
  calendericon: require("./calender.png"),
  showPassword: require("./show_pwd.png"),
  hidePassword: require("./hide_pwd.png"),
  profile: require("./profile.png"),
  copyIcon: require("./copy.png"),
  fullLogo: require("./FullLogo.png"),
  logoMini: LogoMini,
  logoGreenSvg: LogoFull,
  MobileLogo: LogoMini,
  RedCross,
  WhiteCheck,
  profileUser,
  profileOrg,
  ProfileLogout,
  profileNotification,
  SearchIconsvg,
  plusThemeSvg,
  edit,
  action,
  sidebarSiteDetails,
  sidebarAvailibity,
  ProfileNotification,
  plusIcon,
  backIcon,
  forward,
  cross,
  delivery,
  resource,
  recurring,
  multiVehicle,
  alertInfo,
  sitePlan,
  completedStep,
  plusIconGrey,
  deleteIcon,
  minusIcon,
  sorting,
  dragIcon,
  minusGreen,
  plusGreen,
  newDelete,
  modalCloseIcon,
  DeleteGreenIcon,
  DateIcon,
  DeleteRedIcon,
  Subtract,
  UploadIcon,
  CheckIn,
  DeliveryIcon,
  RefuseIcon,
  PDFIcon,
  WhiteDeleteIcon,
  DottedIcon,
  checkIconWhite,
  declinedCloseButton,
  checkOut,
  circleInfo,
  rescheduleSvg,
  CSVlogo,
  CommentLogo,
  Refresh,
  RefreshWhite,
  imageIcon,
  closeIcon,
  tickIcon,
  RedDelete,
  CalendarBack,
  CalendarForward,
  HamburgerMenuIcon,
  ArticulatedVehicle,
  VanIcon,
  BoxTruck,
  CarTrailer,
  CarIcon,
  ConcreteTruck,
  DumpTruck,
  FlatBedTruck,
  GarbageTruck,
  MobileCrane,
  Other,
  PickUpTruck,
  PumpTruck,
  Tanker,
  CalenderIcon,
  CalendarBackArrowIcon,
  CalendarForwardArrowIcon,
  CalendarDownArrow,
  BookingBackGroundImage,
  CheckGreenIcon,
  CalendarBackIcon,
  CalendarForwardIcon,
  ClockIcon,
  ProcoreLogo,
  ModalOutClose,
  ProcoreConfirmAlert,
  ProcoreConfirm,
  ProjectSettingLogo,
  verifiedOrgTick,
  yellowInfo,
  greenCalendarIcon,
  greenResourceIcon,
  greenAccessPoint,
  ApproveCheck,
  DeclineCross,
  RedRefuse,
  BlueCheckIn,
  RefuseBooking,
  CheckInBooking,
  CheckOutBooking,
  CheckInWhite,
  RefuseGreen,
  CheckoutWhite,
  CheckGreen,
  RefuseApply,
  CheckoutApply,
  NextArrow,
  BackArrow,
  Pen,
  Car,
  ConcreteMixer,
  FlatTruck,
  LowerFloor,
  RaisedFloorTrailor,
  RoughTarraineCrane,
  UnicTruck,
  CompletedCheck,
  InCompletedCheck,
};
export default images;
