import ActionType from "../../Actions/ActionType";
import { setIntegrationDataType } from "../../Actions/Procore/ProFeatureAction";

const initialData = {
  data: {},
};

const saveProFeaturesDataReducer = (
  state: any = initialData,
  action: setIntegrationDataType
) => {
  switch (action.type) {
    case ActionType.SET_PROCORE_PRO_FEATURE_DATA:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};
export default saveProFeaturesDataReducer;
