const Translation = {
  invite_users: "ユーザー招待",
  send_invite: "招待を送信する",
  save_details: "詳細を保存する",
  email: "メール",
  mobile: "Web/アプリ",
  role: "権限",
  error_email_required: "メールアドレスは必須です",
  error_valid_email: "メールアドレスが有効ではありません",
  error_role_required: "権限が必要です",
  error_firstName_required: "名前は必須です",
  error_access_point_required: "搬出入ゲートは必須です",
  error_something_went_wrong: "問題が発生しました",
  invite_success: "ユーザーの招待に成功しました",
  error_login_again:
    "ログイントークンの有効期限が切れました。もう一度ログインしてください。",
  access_denied: "アクセス権がありません",
  invalid_email_domain:
    "個人メールアドレスをVoyage Controlに招待することは出来ません。ビジネス用のメールアドレスを使用してください。",
  user_management: "ユーザー管理",
  add_another: "予定のチェックインを追加",
  invite_later: "後で招待する",
  close: "閉じる",
  filter: "フィルタ",
  please_select_site: "現場を選択してください。",
  name_required: "氏名は必須です",
  start_time_required: "開始時間は必須です",
  end_time_required: "終了時間は必須です",
  select_site: "現場を選択します",
  resource_operating_time: "場内機材の稼働時間",
  all_day: "終日",
  breaks: "現場クローズ（曜日＆時間指定）",
  add_break: "現場クローズ（曜日＆時間指定）を追加する",
  name: "名称",
  days_required: "日にちを選択してください",
  days_active: "曜日",
  all_days: "すべての日",
  all_accesspoint: "すべての搬出入ゲートを表示",
  all_resources: "すべての場内機材を表示",
  start_time: "開始時間",
  end_time: "終了時間",
  resources: "場内機材",
  vehicles: "乗り物",
  resource_required: "場内機材は必須です",
  cancel: "キャンセル",
  save: "保存",
  edit_break: "現場クローズ（曜日＆時間指定）を編集",
  start_time_add_required: "開始時間は必須です",
  end_time_add_required: "終了時間は必須です",
  remove: "削除",
  resource_closure: "現場クローズ（日付＆ゲート指定）",
  add_resource_closure: "現場クローズ（日付＆ゲート指定）を追加する",
  edit_resource_closure: "クローズする場内機材を編集します",
  dates: "予定日",
  start_date_required: "開始日を選択してください",
  end_date_required: "終了日を選択してください",
  organization_Role_updated: "組織権限が更新されました",
  clear_all: "すべてクリア",
  status: "ステータス",
  project_role: "プロジェクト権限",
  organization: "組織",
  first_name: "名",
  last_name: "姓",
  email_address: "メールアドレス",
  associated_organization: "組織",
  no_data_found: "データが見つかりません",
  access_point: "搬出入ゲート",
  access_point_required: "搬出入ゲートは必須です",
  vehicle_type: "車両タイプ",
  vehicle_type_required: "車両タイプを選択してください",
  resource: "場内機材",
  next: "次へ",
  contact_desc:
    "お問い合わせはSite Super Steven（steve@sitesuper.com）に連絡してください。モバイルアプリはこちらからダウンロードしてください。搬出入スケジュールの確認が出来ます。",
  full_site_plan: "配置計画図を表示",
  site_plan: "配置計画図",
  on: " ",
  on_every: " ",
  vehicle: "車両",
  vehicle_tracking: "車両追跡",
  select_role: "権限を選択します",
  please_select_time: "開場時間の中で時間を選択してください",
  please_select_time_in_resource: "場内機材の時間を選択してください",
  no_resource_found: "場内機材が見つかりません",
  remove_last: "最後に削除します",
  remove_vehicle: "車両の取り外し",
  filters: "フィルタ",
  saved_details_success_fully: "詳細の保存に成功しました",
  last_name_required: "姓は必須です",
  phone_required: "電話番号は必須です",
  invalid_phone: "電話番号が無効です",
  can_not_remove_project_owner: "プロジェクトオーナーは削除出来ません",
  details: "詳細",
  phone_number: "電話番号",
  company: "組織",
  roles: "権限",
  organization_role: "組織権限が更新されました",
  remove_user_from_project: "このユーザーをプロジェクトから削除",
  booking_list: "予約リスト",
  all: "すべて",
  go: "進む",
  submit: "送信",
  submit_booking: "予約申請",
  back: "戻る",
  start: "始める",
  done: "終わり",
  Additional_Comment: "特筆事項",
  Comment: "コメント",
  company_name_required: "組織名は必須です",
  full_name_is_required: "氏名は必須です",
  email_not_valid: "メールアドレスが無効です",
  email_required: "メールアドレスは必須です",
  mobile_number_required: "電話番号は必須です",
  mobile_number_not_valid: "電話番号が無効です",
  site_contact: "連絡先",
  booking_contact: "問い合わせ先",
  company_name: "組織名",
  full_name: "氏名",
  email_Address: "メールアドレス",
  Mobile_number: "電話番号",
  use_my_details: "あなたの情報を自動入力します",
  select_options: "オプションを選択してください",
  text_field_required: "テキスト欄は必須です",
  checkbox_required: "チェックボックスは必須です",
  file_upload_required: "ファイルのアップロードは必須です",
  comments_required: "コメントは必須です",
  please_select_time_slot: "予約スロットを選択してください",
  Hours: "時間",
  Minutes: "分",
  Maximum: "最大",
  Minimum: "最小",
  booking: "予約",
  Get_Slots: "予約スロットを更新",
  booking_which_time_on: "予約スロットを選択します",
  Show_more_time_slots: "その他の予約スロットを表示する",
  Afternoon: "午後（昼）",
  Evening: "午後（夜）",
  Morning: "午前",
  comments: "コメント",
  title: "タイトル",
  phone: "電話番号",
  question: "質問",
  file_uploaded: "ファイルが正常にアップロードされました",
  files: "ファイル",
  upload_new_file: "新しいファイルをアップロードする",
  no_notes: "メモはまだありません",
  no_files: "ファイルはまだありません",
  no_notes_displayed: "メモを追加すると、ここに表示されます",
  no_files_displayed: "ファイルを追加すると、ここに表示されます",
  add_note: "メモを追加",
  action: "アクション",
  action_by: "アクションを実行したユーザー",
  show_linked_recurring: "関連する定期予約を表示",
  For_Approval: "承認前",
  Scheduled: "承認済み",
  Checked_In: "チェックイン",
  Checked_out: "チェックアウト",
  Declined: "否認",
  Cancelled: "キャンセル",
  Refused: "入場拒否",
  cant_undo: "この操作は元に戻せません",
  are_you_sure: "本当に実行してもよろしいですか",
  are_you_sure_cancel_booking: "この予約をキャンセルしてもよろしいですか？",
  are_you_sure_cancel_all_booking:
    "この定期予約をすべてキャンセルしてもよろしいですか？",
  are_you_sure_decline_booking: "この予約を否認してもよろしいですか？",
  yes: "はい",
  no: "いいえ",
  linked_booking: "その他の定期的な予約",
  notification_preference: "通知設定",
  my_booking_content:
    "本メニューでは、あなたが申請した予約に関する通知の受け取り設定をすることができます。どの操作がされたときに通知するか、また通知方法を設定することができます。​",
  my_org_booking_content:
    "本メニューでは、あなたの組織メンバーが申請した予約に関する通知の受け取り設定をすることができます。どの操作がされたときに通知するか、また通知方法を設定することができます。​",
  all_project_booking_content:
    "本メニューでは、本プロジェクトすべての予約に関する通知の受け取り設定をすることができます。どの操作がされたときに通知するか、また通知方法を設定することができます。​",
  save_changes: "変更を保存する",
  to_all_recurring: "すべての予約",
  this_only: "この予約のみ",
  part_of_recurring_decline:
    "この予約は定期予約の一部です。この予約のみを否認しますか？あるいは関連するすべての予約を否認しますか？",
  part_of_recurring_approve:
    "この予約は定期予約の一部です。この予約のみを承認しますか？あるいは関連するすべての予約を承認しますか？",
  part_of_recurring_cancel:
    "この予約は定期予約の一部です。この予約のみをキャンセルしますか？あるいは関連するすべての予約をキャンセルしますか？",
  edit: "編集",
  move: "日時変更",
  download_pass: "入退場パスのダウンロード",
  expected_vehicles: "予定チェックイン数",
  scheduling_information: "スケジュール情報",
  scheduling_information_tooltip:
    "オプションに必要な情報が不足している場合は、プロジェクト管理セクションで自由に変更するか、プロジェクト管理者に問い合わせてください。",
  okay: "OK",
  collapse: "折りたたむ",
  required_text_indication_text: "*が付いている欄は入力必須です",
  check_in_time: "チェックイン時間",
  check_out_time: "チェックアウト時間",
  cancel_booking: "予約をキャンセル",
  cancel_all_booking: "予約をすべてキャンセルします",
  decline_booking: "予約を否認します",
  edit_booking_details: "予約の詳細を編集します",
  booking_details: "問い合わせ先",
  additional_details: "その他",
  preference_updated_successfully: "通知設定を更新しました",
  automatic_approved: "自動承認",
  you_are_admin: "あなたは組織の管理者になります",
  new_booking: "予約申請",
  select_country: "国を選択します",
  required_country: "国名は必須です",
  country: "国",
  confirm_move_booking: "予約の日時変更",
  current_date_slot: "現在の予約日",
  current_time_slot: "現在の予約時間",
  move_which_time_on: "変更後の予約日時",
  show_more_slot: "その他の予約スロットを表示する",
  move_booking: "実行する",
  remove_user_confirm: "このユーザーを削除してもよろしいですか？",
  page: "ページを選択する",
  join_company: "組織に参加する",
  log_into: "ログイン",
  dont_recognize_company: "この組織は認識されていません",
  units: "枠まで",
  export_tooltip_message:
    "エクスポートすると、準備ができたら自動的にダウンロードが開始されます。また、後でダウンロードするためのリンクもメールで送信されます。",
  select_booking: "続行するには、予約タイプを選択する必要があります",
  what_kind_of_booking: "予約の種類を選択してください",
  expected_number_vehicle: "予定チェックイン数",
  email_us: "メールでのお問い合わせ先",
  please_contact_us: "お問い合わせください",
  previous: "前へ",
  please_select_slot: "予約スロットを選択してください",
  please_select_date: "日付を選択してください",
  booking_moved: "予約の日時を変更しました",
  booking_edited: "予約を編集しました",
  logout: "ログアウト",
  help: "ヘルプ",
  none: "なし",
  created_by: "予約申請者",
  hours_restricted_tooltip_text:
    "現在時刻から締め切り時間の間、ユーザーが予約申請することができなくなります。例えば、締め切り時間を2時間と設定した場合は、現時刻から2時間以内の予約申請をすることができなくなります。",
  days_allowed_in_advance_tooltip_text:
    "本日から予約可能日数までの間のみで、ユーザーが予約申請できます。例えば60日に設定した場合は、本日から60日を超える予約申請ができなくなります。",
  admin_portal: "管理者ポータル",
  coming_soon: "Coming Soon",
  project_name: "プロジェクト名",
  create_account: "アカウント作成",
  time_left_complete_booking: "この申請の有効時間",
  reselect_timeslot: "予約スロットを再選択",
  calendar: "カレンダー",
  mine: "自分",
  time: "予定時間",
  booking_type: "予約タイプ",
  contact_org: "組織の連絡先",
  resource_availability_updated: "場内機材のアベイラビリティを更新しました",
  week: "週",
  day: "日",
  show_weekends: "週をすべて表示する",
  show_canceled: "キャンセルされた予約を表示する",
  today: "今日",
  loading_availability: "空き状況を読み込み中です。お待ちください",
  cant_move_on_unavailable_timeslot:
    "空きのない時間帯に日時変更することは出来ません",
  no_slot_available: "この予約を申請できる予約スロットがありません",
  you_dont_have_permission: "この予約にアクセスする権限がありません",
  cant_booking_cause: "この予約の日時変更が出来ない理由",
  you_dont_have_permission_for_move_booking:
    "この予約の日時変更をする権限がありません",
  cant_move_booking_in_past:
    "現在時刻よりも前に予約スロットを移動することは出来ません",
  max_unit_should_not_negative: "maxUnitには0以上を入力してください",
  please_enter_max_quantity: "最大数量を入力してください",
  please_select_project: "プロジェクトを選択してください",
  replace: "変更する",
  number_of_days_booking_label: "予約可能日数",
  number_of_hours_booking_label: "締め切り時間（h）",
  advance_booking: "予約申請に関する制限設定",
  error_end_date_required: "終了日は必須です",
  error_star_date_required: "開始日は必須です",
  error_field_required: "この入力欄は必須です",
  success_response_site_availability: "アベイラビリティの設定を更新しました",
  hours_of_operation: "開場時間",
  break: "現場クローズ（曜日＆時間指定）",
  error_name_required: "名称は必須です",
  error_starttime_required: "開始時間は必須です",
  error_end_time_required: "終了時間は必須です",
  day_active: "有効日数",
  site_closure: "現場クローズ（日付＆ゲート指定）",
  add_site_closure: "現場クローズ設定を追加する",
  edit_site_closure: "現場クローズ（日付＆ゲート指定）を追加する",
  error_message_site_availability: "開場時間を選択してください",
  error_message_availability: "場内機材の時間を選択してください",
  to: "～",
  please_select_days: "日にちを選択してください",
  make_booking_info_message: "予約申請画面で表示するメッセージ​",
  welcome_text: "Voyage Controlへようこそ",
  pls_login: "ログインしてください",
  password: "パスワード",
  forgot_password: "パスワードをお忘れですか？",
  login: "ログイン",
  check_info: "情報を確認しています",
  success_message: "ログインに成功しました",
  error_password_required: "新しいパスワードは必須です",
  error_valid_password: "このパスワードは正しくありません",
  comman_error_message:
    "このメールアドレスのアカウントは存在しません。プロジェクト管理者に連絡して招待をリクエストしてください。",
  reset_password: "パスワードのリセット",
  welcometext_reset: "パスワードは8文字以上必要です",
  confirmpassword: "パスワードを確認する",
  error_confirm_password_required: "新しいパスワード（再入力）は必須です​",
  error_password_upper: "大文字を少なくとも1文字含む",
  error_password_lower: "小文字を少なくとも1文字含む",
  error_password_number: "数字を少なくとも1文字含む",
  error_password_special: "記号を少なくとも1文字含む",
  error_password_min: "最小8文字",
  error_password_max: "最大16文字",
  error_password_not_match: "パスワードがマッチしません",
  backtologin: "ログイン画面に戻る",
  welcometext_forgot:
    "メールアドレスを入力してください。パスワードをリセットする手順をメールで送信します。",
  set_your_company: "会社情報を設定",
  companyname: "会社名",
  address1: "住所1",
  address2: "住所2",
  town_city: "市区町村",
  zip_code: "郵便番号",
  company_success_message: "組織に参加しました",
  error_companyname_required: "会社名は必須です",
  error_address_required: "住所は必須です",
  error_country_required: "国は必須です",
  profile_welcome_text: "プロフィールを設定します",
  firstname: "名",
  lastname: "姓",
  phoneNumber: "電話番号",
  error_firstname_required: "名は必須です",
  error_lastname_required: "姓は必須です",
  error_phonenumber_required: "電話番号は必須です",
  error_valid_phonenumber: "正しい電話番号を入力してください",
  details_saved: "設定を更新しました",
  my_account: "マイアカウント",
  my_details: "個人情報",
  update_password: "パスワードを変更",
  my_preferences: "個人設定",
  calendar_start_day: "カレンダー開始日",
  my_roles: "組織権限",
  added_contact: "連絡先を追加しました",
  deleted_contact: "連絡先を削除しました",
  upload: "アップロード",
  pls_Signup: "サインアップしてください",
  createaccount: "アカウント作成",
  org_role: "組織権限",
  choose_project: "プロジェクトを選択",
  this_project_only: "このプロジェクトのみ",
  user_removed: "ユーザーを組織から削除します",
  org_role_updated: "組織権限が更新されました",
  no_user_found: "ユーザーが見つかりません",
  current_password: "現在のパスワード",
  new_password: "新しいパスワード",
  new_password_again: "新しいパスワード（再入力）",
  current_password_is_required: "現在のパスワードは必須です",
  org_success_message: "組織の詳細を更新します",
  error_organization_name_required: "組織名は必須です",
  error_organization_address1_required: "住所1は必須です",
  error_organization_address2_required: "住所2は必須です",
  error_organizationTown_required: "市区町村は必須です",
  error_organizationZip_required: "郵便番号は必須です",
  error_organizationLogo_required: "ロゴは必須です",
  error_organizationLogo_invalid:
    "有効な画像ファイルをアップロードしてください",
  organization_details: "組織情報",
  organization_logo: "組織のロゴ",
  error_no_white_space: "空白は使用できません",
  password_length_range: "8～16文字",
  enter_valid_password: "有効なパスワードを入力してください",
  details_heading_text: "詳細",
  details_image_text: "イメージ",
  label_sitename: "プロジェクト名",
  label_siteadd: "現場住所",
  label_logo: "ロゴ",
  label_home_banner: "ホームバナー",
  label_home_screen_message: "トップページのメッセージ",
  error_image_invalid: "有効な画像ファイルをアップロードしてください",
  error_siteLogo_required: "サイトロゴは必須です",
  error_homebanner_required: "バナー画像は必須です",
  error_sitename_required: "サイト名は必須です",
  error_siteadd_required: "現場住所は必須です",
  error_homemessage_required: "ホーム画面のメッセージは必須です",
  contact_heading_text: "連絡先",
  add_contact: "新しい連絡先を追加します",
  edit_contact: "連絡先を編集",
  label_name: "名前",
  label_position: "役職",
  label_phone: "電話番号（任意）",
  btn_add: "追加",
  btn_remove: "削除",
  btn_edit: "保存",
  btn_save: "保存",
  change_associated_organization: "関連する組織を変更します",
  confirm: "確認",
  new_organization: "新しい組織",
  changed_organization: "組織の変更に成功します",
  changed_organization_tooltip:
    "関連組織は変更される場合があります。 ユーザーアクションメニューを確認して、「関連組織の変更」オプションを見つけます。",
  error_position_required: "役職は必須です",
  resourse_add: "場内機材を追加しました",
  vehicle_list_updated: "車両リストを更新しました",
  resource_remove: "場内機材を削除しました",
  resource_edit: "場内機材を更新しました",
  access_point_heading_text: "搬出入ゲート",
  btn_cancel: "キャンセル",
  btn_GO: "進む",
  add_access_point: "新しい搬出入ゲートを追加する​",
  edit_access_point: "搬出入ゲートを編集する",
  label_maxUnit: "ゲートキャパシティ",
  error_MaxUnit_required: "ゲートキャパシティが必須です",
  error_MaxUnit_not_negative_number:
    "ゲートキャパシティは0以上を入力してください",
  no_data_found_error: "搬出入ゲートが見つかりません",
  no_available_flex_forms: "使用可能なフレックスフォームが見つかりません",
  add_response_message: "搬出入ゲートを追加しました",
  edit_response_message: "搬出入ゲートを編集しました",
  delete_response_message: "搬出入ゲートを削除しました",
  resource_heading_text: "場内機材",
  vehicles_heading_text: "乗り物",
  resource_text: "場内機材",
  accesspoint_text: "搬出入ゲート",
  maxqty_text: "数量",
  active_text: "アクティブ",
  account_success_message: "アカウントの詳細を更新します",
  error_Phone_required: "電話番号は必須です",
  error_dateformat_required: "日付形式は必須です",
  error_language_required: "言語設定は必須です",
  error_timeformat_invalid: "日付形式は必須です",
  error_startday_required: "開始日は必須です",
  error_Phone_invalid: "電話番号が有効ではありません",
  error_email_invalid: "メールアドレスが有効ではありません",
  apply_filter: "フィルタを適用",
  login_again: "トークンの有効期限が切れました。再度ログインしてください",
  something_went_wrong: "問題が発生しました",
  booking_created: "予約を申請しました",
  thank_you_for_booking_your_delivery_into: "予約申請が完了しました。",
  description:
    "この予約のステータスは[承認前]であり、まだ予約は確定しておりません。承認されると予約ステータスが[承認済み]になり、メール通知が届きます。",
  summary: "サマリ",
  booking_id: "予約ID",
  date: "予定日",
  go_to_list_view: "予約リストを見る",
  make_another_booking: "続けて予約する",
  take_a_note: "以下の現場ルールにご注意ください",
  ppe_worn: "現場では常にPPEを着用してください",
  vehicle_not_allowed:
    "高視認性ベストを着用していない場合、車両は敷地内に入れません",
  priority: "割り当てられた時間に到着する車両が優先されます",
  see_all: "もっと表示",
  see_less: "表示を減らす",
  scheduled: "スケジュールしました",
  repeats: "繰り返し",
  anticipated_no_check_ins: "予想されるチェックイン数",
  start_date_is_required: "開始日を選択してください",
  end_date_is_required: "終了日を選択してください",
  select_day: "日付を選択してください",
  date_is_required: "日付を選択してください",
  choose_date: "日付を選択",
  choose_start_date: "開始日を選択",
  choose_end_date: "終了日を選択",
  repeat_every: "間隔",
  repeat_type_required: "定期的な予約タイプは必須です",
  repeat_on: "繰り返し",
  month_week_required: "月週は必須です",
  month_day_required: "月日は必須です",
  check_in: "チェックイン",
  refuse_entry: "入場拒否",
  checkout_desc:
    "チェックアウトが完了しました。この予約に必要なアクションはありません。",
  booking_refused_desc:
    "入場拒否されました。この予約に必要なアクションはありません。",
  booking_decline_desc:
    "否認されました。この予約に必要なアクションはありません。",
  cancel_desc: "キャンセルされました。この予約に必要なアクションはありません。",
  approve: "承認",
  reschedule: "再申請",
  decline: "否認",
  drag_and_drop: "ファイルをドラッグ&ドロップしてください",
  browse_file: "ファイルをアップロード",
  welcome_to: "ようこそ",
  project_management: "プロジェクト管理",
  project_details: "プロジェクトの詳細",
  availibility: "予約申請設定",
  my_organization: "組織設定",
  notification_preferences: "通知設定",
  language: "言語",
  date_format: "日付形式",
  time_format: "時間形式",
  users: "ユーザー",
  no_resource_available_for_access_point: "使用可能な場内機材がありません",
  project_setting: "プロジェクト設定",
  procore_connection_desc:
    "Voyage ControlをProcoreプロジェクトに接続すると、Procore Daily Logへの配信情報の記録など、さまざまな追加機能が利用できます。",
  edit_integration: "インテグレーションを編集",
  procore_login_info_message:
    "Procoreのログイン資格情報が必要です。ProcoreマーケットプレイスからVoyage Controlアプリをインストールしてください。追加の権限を有効にする必要がある場合もあります。これらの権限は、ProcoreマーケットプレイスのVoyage Controlページで確認できます。",
  connect: "接続",
  disconnect: "切断",
  procore_company_desc:
    "Procoreの会社IDとプロジェクトID、ProcoreのマーケットプレイスでVoyageアプリをインストールしていることが必要です。追加の権限を有効にする必要がある場合があります。これらの権限は、ProcoreマーケットプレイスのVoyage Controlページで確認できます。",
  project: "プロジェクト",
  confirm_disconnect_message: "Procoreから切断してもよろしいですか？",
  disconnecting_cause_feature_not_working:
    "Procoreから切断すると、一部の機能が停止する場合があります。",
  additional_features: "追加機能",
  automatic_approvals: "自動承認",
  automatic_approvals_details:
    "すべての予約は、作成後すぐに自動的に承認されます。",
  log_action_to_daily_log: "ログアクションからProcore Daily Logへ",
  log_action_to_daily_log_detail:
    "予約に対してアクションが実行されるたびに、そのアクションはProcore Daily Logに記録されます。この機能を使用するには、Procore統合のセットアップが必要です。これは、[統合]タブで実行できます。",
  edit_actions: "設定",
  choose_booking_action: "予約アクションを選択",
  choose_booking_action_detail:
    "予約に対して選択したアクションが行われるたびに、Procoreのデイリーデリバリーログに記録されます。",
  Edited: "編集",
  Moved: "日時変更",
  are_you_sure_disconnect_daily_log: "Procoreのデイリーログを無効にしますか？",
  are_you_sure_connect_daily_log:
    "この機能を有効にするには、Procore統合を接続する必要があります。インテグレーション（統合）に進みますか？",
  are_you_sure_disconnect_daily_log_detail:
    "この機能を無効にすると、予約アクションはProcoreのデイリーデリバリーログに記録されなくなります。以前にログに記録されたアクションは、Procoreに残ります。",
  are_you_sure_connect_daily_log_detail:
    "Procoreと接続して、Procore Daily Log機能を有効にします。",
  go_to_integrations: "[統合（Integrations）]に移動",
  organization_name: "組織名",
  upload_logo: "ロゴをアップロード",
  click_to_browse: "クリックして参照または",
  drag_n_drop_your_file: "ファイルをドラッグ&ドロップ",
  pending_approval: "承認待ち",
  approved: "承認済み",
  checked_in_multi_vehicle: "チェックイン（複数入退場）",
  refused_multi_vehicle: "入場拒否（複数入退場）",
  checked_out_multi_vehicle: "チェックアウト（複数入退場）",
  pending_approval_detail: "あなたが予約を申請したことを通知します。​",
  booking_approved_detail:
    "あなたが申請した予約が承認されたことを通知します。​",
  booking_declined_detail:
    "あなたが申請した予約が否認されたことを通知します。​",
  booking_checked_in_detail:
    "あなたが申請した予約について、ドライバーが現場に到着し、入場（チェックイン）したことを通知します。",
  booking_refused_detail:
    "あなたが申請した予約について、ドライバーが現場に到着したものの、入場拒否されたことを通知します。​",
  booking_checked_out_detail:
    "あなたが申請した予約について、ドライバーが退場（チェックアウト）したことを通知します。​",
  booking_checked_in_multi_vehicle_detail:
    "あなたが申請した[複数入退場]の予約について、ドライバーが現場に到着し、入場（チェックイン）したことを通知します。ドライバーがチェックインするたびに通知が届きます。​",
  booking_refused_multi_vehicle_detail:
    "あなたが申請した[複数入退場]の予約について、ドライバーが現場に到着したものの、入場拒否されたことを通知します。ドライバーが入場拒否されるたびに通知が届きます。​",
  booking_checked_out_multi_vehicle_detail:
    "あなたが申請した[複数入退場]の予約について、ドライバーが退場（チェックアウト）したことを通知します。チェックアウトするたびに通知が届きます​。",
  booking_cancelled_detail:
    "あなたが申請した予約がキャンセルされたことを通知します。​",
  booking_edited_detail: "あなたが申請した予約が編集されたことを通知します。​",
  booking_moved_detail:
    "あなたが申請した予約が日時変更されたことを通知します。​",
  new_user_registered: "新しいユーザーが登録されました",
  required_approval: "予約申請",
  pending_approval_detail_my_org:
    "あなたの組織のメンバーが、予約申請したことを通知します。",
  booking_approved_detail_my_org:
    "あなたの組織メンバーが申請した予約が承認されたことを通知します。​",
  booking_declined_detail_my_org:
    "あなたの組織メンバーが申請した予約が否認されたことを通知します。",
  booking_checked_in_detail_my_org:
    "あなたの組織メンバーが申請した予約について、ドライバーが現場に到着し、入場（チェックイン）したことを通知します。​",
  booking_refused_detail_my_org:
    "あなたの組織メンバーが申請した予約について、ドライバーが現場に到着したものの、入場拒否されたことを通知します。​",
  booking_checked_out_detail_my_org:
    "あなたの組織メンバーが申請した予約について、ドライバーが退場（チェックアウト）したことを通知します。",
  booking_checked_in_multi_vehicle_detail_my_org:
    "あなたの組織メンバーが申請した[複数入退場]の予約について、ドライバーが現場に到着し、入場（チェックイン）したことを通知します。ドライバーがチェックインするたびに通知が届きます。​",
  booking_refused_multi_vehicle_detail_my_org:
    "あなたの組織メンバーが申請した[複数入退場]の予約について、ドライバーが現場に到着したものの、入場拒否されたことを通知します。ドライバーが入場拒否されるたびに通知が届きます。​",
  booking_checked_out_multi_vehicle_detail_my_org:
    "あなたの組織メンバーがが申請した[複数入退場]の予約について、ドライバーが退場（チェックアウト）したことを通知します。チェックアウトするたびに通知が届きます。​",
  booking_cancelled_detail_my_org:
    "あなたの組織メンバーが申請した予約がキャンセルされたことを通知します。​",
  booking_edited_detail_my_org:
    "あなたの組織メンバーが申請した予約が編集されたことを通知します。",
  booking_moved_detail_my_org:
    "あなたの組織メンバーが申請した予約が日時変更されたことを通知します。",
  pending_requires_detail_all_proj:
    "本プロジェクトに関連するすべての予約について、予約が申請されたことを通知します。​",
  booking_approved_detail_all_proj:
    "本プロジェクトに関連するすべての予約について、予約が承認されたことを通知します。​",
  booking_declined_detail_all_proj:
    "本プロジェクトに関連するすべての予約について、予約が否認されたことを通知します。​",
  booking_checked_in_detail_all_proj:
    "本プロジェクトに関連するすべての予約について、ドライバーが現場に到着し、入場（チェックイン）したことを通知します。​",
  booking_refused_detail_all_proj:
    "本プロジェクトに関連するすべての予約について、ドライバーが現場に到着したものの、入場拒否されたことを通知します。​",
  booking_checked_out_detail_all_proj:
    "本プロジェクトに関連するすべての予約について、ドライバーが退場（チェックアウト）したことを通知します。​",
  booking_checked_in_multi_vehicle_detail_all_proj:
    "本プロジェクトに関連するすべての予約について、ドライバーが現場に到着し、入場（チェックイン）したことを通知します。ドライバーがチェックインするたびに通知が届きます。​",
  booking_refused_multi_vehicle_detail_all_proj:
    "本プロジェクトに関連するすべての予約について、ドライバーが現場に到着したものの、入場拒否されたことを通知します。ドライバーが入場拒否されるたびに通知が届きます。​",
  booking_checked_out_multi_vehicle_detail_all_proj:
    "本プロジェクトに関連するすべての予約について、ドライバーが退場（チェックアウト）したことを通知します。チェックアウトするたびに通知が届きます。​",
  booking_cancelled_detail_all_proj:
    "本プロジェクトに関連するすべての予約について、予約がキャンセルされたことを通知します。​",
  booking_edited_detail_all_proj:
    "本プロジェクトに関連するすべての予約について、予約が編集されたことを通知します。​",
  booking_moved_detail_all_proj:
    "本プロジェクトに関連するすべての予約について、予約が日時変更されたことを通知します。​",
  new_user_registered_detail:
    "招待されたユーザーが登録を完了し、プロジェクトにアクセスできることを通知します。",
  booking_ref: "予約ID",
  arrival_date: "予定日",
  arrival_time: "入場時間（予定）",
  departure_time: "退場時間（予定）",
  contact_company: "問い合わせ先",
  "For Approval": "承認前",
  "Checked out": "チェックアウト",
  "Checked In": "チェックイン",
  creator_company: "予約申請者の組織名",
  recurring: "定期的な予約",
  delivery: "通常予約",
  "multi-vehicle": "複数入退場",
  "resource-only": "場内機材のみ",
  Van: "バン",
  "18 Wheeler": "18輪トラック",
  "Box Truck": "貨物トラック",
  "Car + Trailer": "乗用車＆トレーラー",
  Car: "乗用車",
  "Concrete Truck": "コンクリートミキサー",
  "Dump Truck": "ダンプカー",
  "Flatbed Truck": "平車",
  "Garbage Truck": "パッカー車",
  "Mobile Crane": "移動式クレーン",
  Other: "その他",
  "Pickup Truck": "ピックアップ・トラック",
  "Pump Truck": "ポンプ・トラック",
  Tanker: "タンカー",

  "Raised Floor Trailer": "高床トレーラー",
  "Low Floor Trailer": "低床トレーラー",
  "15t Flat Truck": "15t平車",
  "15t Unic Truck": "15tユニック車",
  "10t Flat Truck": "10t平車",
  "10t Unic Truck": "10tユニック車",
  "10t dump truck": "ダンプカー10t",
  "10t Dump Truck": "ダンプカー10t",
  "10t arm roll": "アームロール10t",
  "10t Arm Roll": "アームロール10t",
  "4t arm roll": "アームロール4t",
  "4t Arm Roll": "アームロール4t",
  "8t Flat Truck": "8t平車",
  "8t Unic Truck": "8tユニック車",
  "4t Flat Truck": "4t平車",
  "4t Unic Truck": "4tユニック車",
  "2t Flat Truck": "2t平車",
  "2t_Flat_Truck": "2t平車",
  "Concrete Mixer Truck": "生コン車",
  "16t Rough Terrain-Crane": "16tラフテレーンクレーン",
  "25t Rough Terrain-Crane": "25tラフテレーンクレーン",
  "50t Rough Terrain-Crane": "50tラフテレーンクレーン",
  "65t Rough Terrain-Crane": "65tラフテレーンクレーン",

  my_bookings: "自分の予約",
  my_org_bookings: "自分の組織の予約",
  all_project_bookings: "すべての予約",
  Active: "アクティブ",
  Inactive: "非活性",
  Invited: "招待",
  Restricted: "制限",
  Blocked: "ブロック",
  "Project Admin": "プロジェクト管理者",
  "Project Manager": "プロジェクトマネージャー",
  "Project Owner": "プロジェクトオーナー",
  "Project User": "プロジェクトユーザー",
  verified_domains: "検証済みドメイン",
  email_domain: "メールドメイン",
  unverified_organization_info_message:
    "あなたの組織は未承認です。会社のメールドメインで登録したユーザーの組織のみ承認されます。",
  verified_organization_info_message:
    "この組織は承認されており、このメールドメインを持つユーザーは追加機能を利用できます。",
  project_information: "プロジェクト情報",
  site: "現場",
  availability: "予約申請設定",
  unlimited: "数量制限が無い場合",
  Sunday: "日曜日",
  Monday: "月曜日",
  Tuesday: "火曜日",
  Wednesday: "水曜日",
  Thursday: "木曜日",
  Friday: "金曜日",
  Saturday: "土曜日",
  Sun: "日",
  Mon: "月",
  Tue: "火",
  Wed: "水",
  Thu: "木",
  Fri: "金",
  Sat: "土",
  settings: "設定",
  integrations: "インテグレーション",
  select: "選択",
  from: "から",
  booking_information: "予約情報",
  activity: "履歴",
  notes: "メモ",
  January: "1月",
  February: "2月",
  March: "3月",
  April: "4月",
  May: "5月",
  June: "6月",
  July: "7月",
  August: "8月",
  September: "9月",
  October: "10月",
  November: "11月",
  December: "12月",
  site_detail_tooltip:
    "プロジェクト名、現場住所を入力してください。ここで設定した内容はトップページに表示されます。",
  project_address_tooltip:
    "現場住所を入力するか、地図上の任意の場所を選択します。",
  home_screen_message_tooltip:
    "ここに記載したメッセージはトップページに表示されます。",
  project_logo_tooltip:
    "プロジェクトロゴをアップロードすると、プロジェクトのトップページを始め、本システム内のさまざまな場所で表示されます。",
  project_banner_tooltip:
    "トップページのバナー画像をアップロードしてください。",
  project_information_contact_tooltip:
    "主な現場担当者の連絡先を追加してください。ここで追加された連絡先は、トップページに表示されます。",
  information_accesspoint_tooltip:
    "現場内の搬出入ゲート（名称、ゲートキャパシティ）を設定してください。搬出入ゲートは少なくとも1つ以上設定してください。",
  max_unit_tooltip: "同時間帯で予約できる最大数です。",
  information_resource_tooltip:
    "場内機材には、クレーン、ホイスト、フォークリフトのような現場で使用する機材を設定してください。",
  information_vehicle_tooltip:
    "サイトのこのセクションでは、プロジェクトで予約を作成するための車両の空き状況と注文を指定できます。 各プロジェクトには、少なくとも 1 つの使用可能な車両タイプが含まれている必要があります。",
  resource_access_point_tooltip:
    "この場内機材を使用できる搬出入ゲートを選択します。",
  resource_max_quantity_tooltip:
    "同時刻帯でこの場内機材を使用出来る最大数です。",
  site_hours_of_operation_tooltip:
    "現場が稼働している時間を設定してください。ここで設定した時間の中でユーザーは予約申請することができます。[終日]のチェックボックスをオンにするとユーザーが24時間予約できるようになります。",
  site_breaks_tooltip:
    "設定した曜日、時間でユーザーは予約申請することができなくなります。",
  site_closure_tooltip:
    "特定の日付、搬出入ゲートでユーザーは予約申請をすることができなくなります。祝日や長期休暇などを設定することを推奨します。",
  site_booking_creation_message_tooltip:
    "ここに記載したメッセージは予約申請時に表示されます。",
  site_plan_tooltip:
    "現場地図（配置計画図）をアップロードすることで、予約申請時にユーザーが参照することができます。",
  resource_operating_time_tooltip:
    "場内機材を予約できる曜日と時間帯を設定してください。設定日時の中で、場内機材を使用する予約申請をすることができます。[終日]のチェックボックスをオンにするとユーザーが24時間予約できるようになります。",
  resource_closure_tooltip:
    "設定した日付で、ユーザーが場内機材を使用する予約申請をすることができなくなります。​",
  booking_duration_tooltip:
    "搬出入の予定時間を選択してください。複数入退場で予約申請している場合は、すべての車両が搬出入を完了する予定時間を選択してください。",
  duration_hrs: "所要時間",
  booking_contact_tooltip: "予約担当者の連絡先情報を入力します。",
  organization_logo_tooltip: "組織のロゴをアップロードします。",
  verified_domains_tooltip:
    "このドメインのユーザーを招待すると、システムがあなたの組織に所属するように推奨します。​",
  delivery_information_message: "通常の搬出入はこちらを選択してください。",
  recurring_information_message:
    "定期的に搬出入が行われる場合はこちらを選択してください。​",
  multivehicle_information_message:
    "複数の車両が何回も入退場を繰り返す場合はこちらを選択してください。​",
  resource_only_information_message:
    "搬出入が無く、場内機材のみを使用する場合はこちらを選択してください。",
  booking_date: "予定日",
  booking_time: "予定時間",
  Weekly: "毎週",
  Monthly: "毎月",
  First: "最初の",
  Last: "最後の",
  a_password_must_contain: "パスワードは下記の条件を満たす必要があります​",
  organization_updated_successfully: "組織の詳細を更新しました",
  "This field is required.": "このフィールドは必須です",
  contact_edit_successfully: "連絡先の編集に成功しました",
  please_check_your_email: "メールを確認してください",
  for_reset_password: "パスワードリセット用",
  disable_procore_info_tooltip:
    "Pro機能はこのプロジェクトでは有効になっていません。この機能にご興味がある場合は、アカウントマネージャかセールスチーム（sales@voyagecontrol.com）にお問い合わせください。",
  procore_company_and_project_updated:
    "Procore CompanyおよびProjectを正常に更新しました",
  daily_log_action_updated_for_booking_status:
    "予約ステータスのデイリーログアクションが正常に更新されました",
  disconnected_daily_log_action: "デイリーログアクションが切断されました",
  disconnected_procore: "Procoreから切断します",
  connected_daily_log_action: "デイリーログアクションが接続されています",
  invite_from_procore: "Procoreから招待します",
  please_select_user: "少なくとも1人のユーザーを選択してください",
  procore_unauthorize: "認証に失敗しました",
  procore_authorize: "認証に成功しました",
  error_procore_redirect:
    "このプロジェクトでは統合がアクティブではありません。 管理者に連絡してください",
  selected: "選択済み",
  print: "印刷",
  column_config: "予約リストの表示設定",
  new_layout: "新しい表示形式を作成",
  select_column: "表示する項目",
  column_order: "項目の並び替え",
  by_access_point: "搬出入ゲート",
  by_resource: "場内機材",
  label_M: "月",
  label_TUE: "火",
  label_W: "水",
  label_THU: "木",
  label_F: "金",
  label_SA: "土",
  label_SU: "日",
  hour: "時間",
  minute: "分最大",
  admin: "組織権限",

  "Security Guard": "警備員",
  "View Only": "閲覧ユーザー",
  "Project Senior User": "プロジェクトシニアユーザー",
  Auth_err_email_not_found:
    "このアカウントは有効ではありません。プロジェクト管理者に連絡してください。",
  Auth_err_invalid_token: "無効なトークン",
  Auth_err_missing_credentials: "認証資格がありませんでした",
  Auth_err_no_active_account: "ユーザー名またはパスワードが間違っています",
  Auth_err_no_active_user: "このメールアドレスのアカウントが見つかりません",
  Auth_err_project_already_exists: "このプロジェクトは既に存在します",
  Auth_err_similar_password: "新しいパスワードと現在のパスワードが同じです​",
  Auth_err_validating_old_password: "現在のパスワードが正しくありません",
  Auth_msg_password_reset: "パスワードのリセットに成功しました",
  Auth_msg_user_removed_from_project:
    "ユーザーがプロジェクトから削除されました",
  Err_all_access_point_slots_booked:
    "搬出入ゲートのすべてのスロットが予約されています",
  Err_all_resource_slots_booked: "場内機材のすべてのスロットが予約されています",
  Err_all_vehicles_cleared: "残っている車両がありません",
  Err_answer_option_invalid: "指定されたオプションは無効です",
  Err_attachment_booking_mismatch: "この添付ファイルは別の予約のものです",
  Err_body_must_be_list: "ボディはオブジェクトのリストでなければなりません",
  Err_booking_can_not_be_edited_in_current_state:
    "現在のステータスでは予約を編集できません",
  Err_booking_can_not_be_rescheduled_in_current_state:
    "現在のステータスでは予約を再申請することはできません",
  Err_booking_date_in_past: "過去の日付で予約することはできません",
  Err_booking_does_not_exist: "予約が存在しません",
  Err_booking_status_change:
    "現在のステータスではアクションを実行することができません",
  Err_booking_type_requires_access_point: "入退場ゲートの選択が必須です",
  Err_can_not_reschedule_recurring: "定期的な予約は再申請することができません",
  Err_cant_assign_owner_role:
    "オーナープロジェクト権限を別のユーザーに割り当てることはできません",
  Err_cant_book_after_advanced_date: "予定日の後に予約することはできません",
  Err_cant_book_before_advanced_date: "予定日の前に予約することはできません",
  Err_cant_change_own_status_but_cancel:
    "予約ステータスを変更することはできません。キャンセルのみ可能です。",
  Err_cant_move_booking: "日時変更はできません",
  Err_comment_required: "コメントは必須です",
  Err_company_missing: "会社情報がありません",
  Err_contact_required: "連絡先は必須です",
  Err_date_range_not_match_booking_numbers:
    "日付の範囲が予約番号と一致しません",
  Err_date_required: "日時は必須です",
  Err_daterange_must_have_two_values: "日付範囲は必須です",
  Err_does_not_exist: "存在しません",
  Err_domain_not_allowed: "このドメインは許可されていません",
  Err_domains_already_registered:
    "このドメインは、他の組織によって登録されています。",
  Err_duplicate_break:
    "現場クローズ（曜日＆時間指定）と被っているため、更新してください",
  Err_duplicate_closure: "現場クローズと被っているため、更新してください",
  Err_duplicate_organization_name:
    "同じ名前の組織が既に存在します。別の組織名を選択してください。",
  Err_duplicate_site_name: "同じ名前のサイトが既に存在します。",
  Err_duplicate_user_email: "同じメールアドレスのユーザーが既に存在します",
  Err_email_body_not_defined: "ボディを定義する必要があります",
  Err_email_not_found: "メールが見つかりません",
  Err_email_subject_not_defined: "件名を定義する必要があります",
  Err_email_template_not_defined: "テンプレートを定義する必要があります",
  Err_file_ref_invalid: "質問に指定されたファイル参照が無効です",
  Err_incomplete_onboarding: "オンボーディングが未完了です",
  Err_incorrect_user_role: "ユーザーに正しい権限アクセス権がありません。",
  Err_integration_config_not_found:
    "インテグレーション構成オブジェクトがありません",
  Err_integration_disconnect_handler_not_defined:
    "IntegrationEnableHandlersクラスでIntegration Disconnectハンドラが定義されていません",
  Err_integration_handler_not_defined:
    "IntegrationEnableHandlersクラスでIntegrationハンドラが定義されていません",
  Err_integration_not_found: "インテグレーションが有効になっていません",
  Err_invalid_access_point: "無効な搬出入ゲートです",
  Err_invalid_access_point_on_resource:
    "選択した場内機材と搬出入ゲートは無効です",
  Err_invalid_booking_status: "予約ステータスは有効ではありません",
  Err_invalid_booking_type: "予約タイプの選択が無効です",
  Err_invalid_data: "無効なデータです",
  Err_invalid_project: "無効なプロジェクトです",
  Err_invalid_project_role: "無効なプロジェクト権限です",
  Err_invalid_project_status:
    "無効なステータス選択です。[pre-live, active, paused. finished]のいずれかです。",
  Err_invalid_region: "無効な都道府県です",
  Err_invalid_role: "無効な権限です",
  Err_invalid_site: "無効な現場です",
  Err_misconfigured_region: "DB領域の設定に誤りがあります",
  Err_missing_auth_credentials: "認証資格情報が提供されませんでした。",
  Err_missing_recurring_booking_options:
    "[repeat_option, repeat_weekdays, repeat_monthly_option]のいずれかが不足しています",
  Err_missing_some_required_fields: "一部の必須フィールドがありません",
  Err_multi_vehicle_booking_not_supported:
    "このプロジェクトは複数入退場に対応していません",
  Err_must_be_access_point:
    "オブジェクト（Obj）は入退場ゲートである必要があります",
  Err_must_be_resource: "オブジェクト（Obj）は場内機材でなければなりません",
  Err_no_access_to_booking_list: "予約リストにアクセスできません",
  Err_no_booking_dates: "予約日が見つかりません",
  Err_no_slots_for_access_point_and_Resource:
    "この搬出入ゲートおよび場内機材には利用可能な予約スロットがありません",
  Err_not_multi_vehicle_booking: "[複数入退場]の予約ではありません",
  Err_not_part_of_any_organization_or_role: "組織や役割の一部ではありません",
  Err_null_project_role:
    "プロジェクト権限を想定していましたが、NULL値が返ってきました",
  Err_num_vehicles_required:
    "[複数入退場]の予約には車両数を入力する必要があります",
  Err_organization_and_role_mismatch: "組織と組織権限が一致しません",
  Err_organization_domain_exists:
    "このドメインに一致する組織はすでに存在します。",
  Err_organization_not_found: "組織が存在しません",
  Err_owner_email_required: "オーナーのメールアドレスは必須です",
  Err_permission_denied: "この操作は許可されていません",
  Err_preference_not_exist: "環境設定が存在しません",
  Err_procore_auth_code_missing: "Procore認証コードがありません",
  Err_project_name_already_exists: "プロジェクト名はすでに存在します",
  Err_project_not_active_deleted_or_status_not_allowed:
    "プロジェクトがアクティブではないか、削除されています",
  Err_project_not_authenticated_for_integration:
    "このインテグレーションではプロジェクトが認証されていません",
  Err_project_user_does_not_exist: "プロジェクトユーザーが存在しません",
  Err_provide_one_of_access_point_or_resource:
    "access_pointまたはresourceのいずれかを指定しますが、両方は指定しないでください",
  Err_question_not_exist: "質問が存在しません",
  Err_recurring_booking_not_supported:
    "このプロジェクトでは[定期的な予約]は対応していません",
  Err_repeat_monthly_option_missing:
    "repeat_monthly_optionに、[FIRST, LAST]のいずれかは不足しています。",
  Err_repeat_option_missing:
    "repeat_optionに週単位又は月単位が不足しています。",
  Err_repeat_weekdays_expects_single_day:
    "月次オプションのrepeat_weekdaysは、複数日ではなく、1日だけを想定しています。",
  Err_repeat_weekdays_missing:
    "repeat_weekdaysがない場合は、［月、火、水、木、金、土、日］のいずれかです。",
  Err_require_one_of_access_point_or_resource:
    "access_pointまたは、場内機材のいずれかを指定してください",
  Err_required_project_param: "プロジェクト情報は必須です",
  Err_required_region_or_project:
    "都道府県またはプロジェクトのいずれかが必要です",
  Err_reserved_slot_expired_or_used:
    "予約が期限切れになったか、すでに使用されています",
  Err_resource_closure_not_found:
    "現場クローズ（場内機材指定）が見つかりません。",
  Err_resource_not_operational:
    "場内機材がオペレーション用に設定されていません",
  Err_resource_only_booking_not_supported:
    "このプロジェクトでは[場内機材のみ]予約は対応していません",
  Err_resource_required: "場内機材は必須です",
  Err_resource_required_for_resource_only:
    "[場内機材のみ]予約を作成しようとしましたが、指定された予約スロットでは場内機材が予約されませんでした",
  Err_resource_site_mismatch: "場内機材と現場が一致しません",
  Err_resource_timeing_not_found: "場内機材の使用可能時間が見つかりません",
  Err_site_access_point_mismatch: "搬出入ゲートはこの現場のものではありません",
  Err_site_does_not_exists: "現場が存在しません",
  Err_site_resource_mismatch: "場内機材はこの現場のものではありません",
  Err_slot_not_available:
    "予約スロットは使用できません。既に使用されています。",
  Err_slot_not_reserved_for_booking_type:
    "選択されたスロットは、このブッキングタイプに予約されていませんでした",
  Err_slot_uuid_not_found: "このUUIDに一致するスロット予約がありません",
  Err_some_feature_actions_are_not_supported:
    "選択した機能アクションの一部はサポートされていません",
  Err_start_date_greater_than_end: "終了日は開始日より後にしてください",
  Err_start_end_date_required: "開始日と終了日を両方選択してください",
  Err_start_time_greater_than_end:
    "開始時間は終了時間よりも前に設定してください",
  Err_unknown_response_from_external_api: "外部統合APIからの不明な応答です",
  Err_unsupported_export_format: "サポートされていないエクスポートタイプです",
  Err_user_already_onboarded:
    "ユーザーはすでにオンボーディングしているか、招待を受信していません。",
  Err_user_does_not_exist: "ユーザーが存在しません",
  Err_user_has_no_access: "ユーザーにはアクセス権がありません",
  Err_user_inactive: "ユーザーは非アクティブです",
  Err_user_invalid_token: "トークンに認識不可能なユーザーIDが含まれていました",
  Err_user_not_found: "ユーザーが見つかりません",
  Err_user_project_role_not_exists: "ユーザーのプロジェクト権限が存在しません",
  Err_username_not_set: "指定されたユーザ名を設定する必要があります",
  Err_uuid_reserved_another_slot:
    "UUIDは別のスロットを予約するために使用されました",
  Err_uuid_used_create_another:
    "UUIDが使用されています。別のUUIDを作成してください",
  Err_vehicle_not_exist: "車両項目が存在しません",
  Err_vehicle_type_required: "車両タイプは必要です",
  Msg_booking_field_created_success:
    "プロジェクト予約フィールドが正常に作成/更新されました。",
  Msg_user_remove_from_project: "ユーザーがプロジェクトから削除されました",
  Msg_users_invited_success: "ユーザーが招待されました",
  "Not Found": "見つかりません",
  email_optional: "メールアドレス（任意）",
  copied: "コピーしました",
  Checkout: "チェックアウト",
  Refuse: "入場拒否",
  override_constraints:
    "プロジェクト管理者とプロジェクトマネージャーにはこの設定を適用しない",
  override_constraints_tooltip:
    "この設定をオンにすると、プロジェクト管理者とプロジェクトマネージャーの権限に割り当てられているユ－ザーは制限事項に関わらず予約申請をすることができるようになります。",
  region_state: "都道府県",
  error_organizationState_required: "状態は必須です",
  disable: "使用不可",
  core_features: "主な機能",
  pro_features: "プロ機能",
  invite_canceled: "ユーザー招待がキャンセルされました",
  invite_resend: "ユーザー招待が再送信されました",
  cant_move_booking_across: "予約の日時を変更することはできません",
  accesspoint: "搬出入ゲート",
  project_settings_updated: "プロジェクトの設定を更新しました",
  Expired: "期限切れ",
  column_config_new: "新しい表示形式を作成",
  column_config_name: "新しい表示形式の名称",
  column_config_are_you_sure: "本当にこの表示形式を削除してもよろしいですか？",
  column_config_delete: "削除",
  set_default: "この表示形式を初期的に表示する",
  set_default_tooltip: "プロジェクト管理者のみが利用可能",
  column_config_created: "表示形式を作成しました。",
  column_config_updated: "表示形式を変更しました。",
  column_config_deleted: "表示形式を削除しました。",
  column_config_cannot_be_changed: "この構成は変更できません",
  Status: "ステータス",
  Resource: "場内機材",
  "Booking Ref": "予約ID",
  "Arrival Date": "予定日",
  "Arrival Time": "入場時間（予定）",
  "Departure Time": "退場時間（予定）",
  "Access Point": "搬出入ゲート",
  "Vehicle type": "車両タイプ",
  "Contact Company": "問い合わせ先",
  reinstate_booking: "元に戻す",
  reinstate_booking_are_you_sure: "この予約を復元してもよろしいですか?",
  error_more_than_year_in_advance:
    "ロード時間が長くなることを避けるために、365以下の数字を入力してください。",
  only_available: "予約分のみ表示",
  only_booked: "予約しただけ",
  all_resource: "すべての場内機材を表示",
  invite_link_cancelled_desc:
    "招待メールのリンク期限が切れました。もう一度招待をするように管理者に連絡してください。",
  invite_link_cancelled: "ユーザー招待のリンク期限が切れました。",
  any_integration_lost: "インテグレーションの設定をリセットしました。",
  undefined: "月曜日",
};
export default Translation;
