const getBaseUrl = () => {
  return process.env.REACT_APP_BASE_URL;
};
// const getAuthUrl = () => {
//   return process.env.REACT_APP_AUTH_URL;
// };
// export const AUTH_URL = `${getAuthUrl()}/api/auth`;
export const AUTH_URL = `${getBaseUrl()}/api/auth`;
export const BASE_URL = `${getBaseUrl()}/api/v1`;
export const SSO_URL = process.env.REACT_APP_SSO_URL;
export const GOOGLE_API_BASE_URL = "https://maps.googleapis.com/maps/api";

type RefId = string | number;

export const SsoApiUrl = {
  tokenVerify: `${SSO_URL}/api/sso/jwt/token/verify/`,
  tokenRefresh: `${SSO_URL}/api/sso/jwt/token/refresh/`,
  getUserCurrent: `${SSO_URL}/api/sso/users/me/`,
  getUser: (ref: RefId) => `${SSO_URL}/api/sso/users/${ref}/`,
  loginPage: `${SSO_URL}/api/sso/users/login/`,
  logoutPage: `${SSO_URL}/api/sso/users/logout/`,
};

export const ApiUrl = {
  authModule: {
    region: `${AUTH_URL}/projects/regions/`,
    userLogin: `${AUTH_URL}/jwt/token/`,
    userForgotpassword: `${AUTH_URL}/users/password/forgot/`,
    userResetpassword: `${AUTH_URL}/users/password/reset/`,
    projectRoles: `${BASE_URL}/projects/roles/`,
    userStatus: `${BASE_URL}/users/status-count/`,
    userInvite: `${BASE_URL}/users/invite/`,
    userInviteStatus: `${BASE_URL}/users/invite/status/`,
    userInviteResend: `${BASE_URL}/users/invite/resend/`,
    userInviteCancel: `${BASE_URL}/users/invite/cancel/`,
    userProjectList: `${BASE_URL}/projects/`,
    companylist: `${BASE_URL}/users/organization/suggestion/`,
    userSignup: `${BASE_URL}/users/signup/`,
    organizationdata: `${BASE_URL}/users/activate/`,
    authUserApi: `${AUTH_URL}/users/me/`,
    featureFlag: `${AUTH_URL}/feature-flags/`,
  },
  googleAPI: {
    getGeoCode: `${GOOGLE_API_BASE_URL}/geocode/json`,
    getTimeZone: `${GOOGLE_API_BASE_URL}/timezone/json`,
  },
  profileModule: {
    getProjectDetailsCall: `${BASE_URL}/projects/`,
    getAccountUserDetails: `${BASE_URL}/users/me/`,
    getAccountUserPreferenceDetails: `${BASE_URL}/users/mypreference/`,
    getLanguages: `${BASE_URL}/preferences/language/`,
    getDateFormates: `${BASE_URL}/preferences/date/format/`,
    getTimeFormates: `${BASE_URL}/preferences/time/format/`,
    getCalenderDays: `${BASE_URL}/preferences/calender/days/`,
    addUserDetail: `${BASE_URL}/users/me/`,
    userUpdatepassword: `${AUTH_URL}/users/password/change/`,
    getOrganizationDetails: `${BASE_URL}/organizations/me/`,
    getProjectList: `${BASE_URL}/projects/me/`,
    getUserList: `${BASE_URL}/organizations/users/`,
    getOrganizationRoleList: `${BASE_URL}/organizations/roles/list/`,
    updateOrgRole: `${BASE_URL}/organizations/role/update/`,
    removeUserFromOrg: `${BASE_URL}/organizations/user/remove/`,
    updateOrganizationDetails: `${BASE_URL}/organizations/`,
    getBookingDetailData: `${BASE_URL}/booking-fields/`,
  },
  siteManagement: {
    getSiteList: `${BASE_URL}/sites/`,
    getListProject: `${BASE_URL}/projects/`,
    getListAccesspoint: `${BASE_URL}/projects/access-point/`,
    getSiteDetail: `${BASE_URL}/projects/details/`,
    getContactList: `${BASE_URL}/projects/contacts/`,
    getResourceList: `${BASE_URL}/projects/resource/`,
    getTimes: `${BASE_URL}/legacy/time/`,
    getSite: `${BASE_URL}/sites/`,
    getSiteAvailablity: `${BASE_URL}/projects/sites/availability/`,
    getSiteVehicles: `${BASE_URL}/booking-fields/vehicle-type/`,
  },
  Availability: {
    getResourceList: `${BASE_URL}/projects/resource/`,
    getAvailability: `${BASE_URL}/availability/`,
    getTimes: `${BASE_URL}/legacy/time/`,
    addSiteBreak: `${BASE_URL}/projects/sites/`,
  },
  UserManagement: {
    getUserList: `${BASE_URL}/users/`,
    getUserdetailbyid: `${BASE_URL}/users/`,
    postUserdetail: `${BASE_URL}/users/`,
    userUpdateOrganization: `${BASE_URL}/users/organization/change`,
    organization: `${BASE_URL}/organizations/`,
    organizationUserFilter: `${BASE_URL}/organizations/project-user-count/`,
    updateProjectRole: `${BASE_URL}/projects/role/update/`,
    removeUserFromProject: `${BASE_URL}/projects/user/remove/`,
    getUserData: `${BASE_URL}/users/minimal`,
  },
  booking: {
    vehicleTypes: `${BASE_URL}/projects/vehicle-type/`,
    reserveSlots: `${BASE_URL}/bookings/slots/reserve/`,
    createBookingcall: `${BASE_URL}/bookings/`,
    slots: `${BASE_URL}/slots/`,
    getProjectSetting: `${BASE_URL}/projects/settings/`,
    bookingList: `${BASE_URL}/bookings/`,
    bookingView: `${BASE_URL}/column-view/`,
    bookingStatusUpdate: `${BASE_URL}/bookings/`,
    uploadFlexFiles: `${BASE_URL}/booking-fields/attachments/`,
    activity: `${BASE_URL}/auditlog/booking/`,
    flexForm: `${BASE_URL}/booking-fields/forms`,
  },
  notifications: {
    defaultPreference: `${BASE_URL}/notifications/preferences/me`,
  },
  projectSettings: {
    proFeature: `${BASE_URL}/projects/pro-features/`,
    integrations: `${BASE_URL}/integrations/`,
    companies: `${BASE_URL}/integrations/procore/companies/`,
    procoreUsers: `${BASE_URL}/integrations/procore/projects/`,
    organizations: `${BASE_URL}/organizations/list-for-invite/`,
  },
};
export const getUrl = (url1: string, url2: string, site: any) => {
  return url1 + site + url2;
};
