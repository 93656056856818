import ActionType from "../../Actions/ActionType";
import { bookingDetailAction } from "../../Actions/BookingDetails/BookingDetailAction";
const initialState = {
  bookingData: {},
};

const bookingDetailReducer = (
  state: object = initialState,
  action: bookingDetailAction
) => {
  switch (action.type) {
    case ActionType.SET_BOOKING_DETAIL:
      return {
        ...state,
        bookingData: action.data,
      };
    default:
      return initialState;
  }
};

export default bookingDetailReducer;
