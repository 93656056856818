import { getLocalStorage } from "../Network/ApiService";
import { constants, ls_vars } from "../Library/Constants";
import { useCallback } from "react";

export const SETTINGS_KEYS = {
  ENABLE_MULTIPLE_CHECKINS_ON_BOOKING: "ENABLE_MULTIPLE_CHECKINS_ON_BOOKING",
  ENABLE_RECURRING_BOOKING: "ENABLE_RECURRING_BOOKING",
  ENABLE_RESOURCE_ONLY_BOOKING: "ENABLE_RESOURCE_ONLY_BOOKING",
  ENABLE_AUTO_APPROVAL: "ENABLE_AUTO_APPROVAL",
  VIEW_PROJECT_SETTINGS: "VIEW_PROJECT_SETTINGS",
  VIEW_INTEGRATIONS: "VIEW_INTEGRATIONS",
  VIEW_VEHICLE_TRACKING: "VIEW_VEHICLE_TRACKING",
  PRO_FEATURE_PROCORE_DAILY_LOG: "PRO_FEATURE_PROCORE_DAILY_LOG",
  PRO_FEATURE_FORS_VALIDATION: "PRO_FEATURE_FORS_VALIDATION",
  INTEGRATION_PROCORE: "INTEGRATION_PROCORE",
};

export type ProjectSettingsKey = keyof typeof SETTINGS_KEYS;

export const useProjectSettings = () => {
  const projectSettings = getLocalStorage(ls_vars.PROJECT_SETTINGS);

  const checkSetting = useCallback(
    (key: string) => {
      const array = Array.isArray(projectSettings) ? projectSettings : [];
      const setting = array?.find((item: { key: string }) => item.key === key);
      return setting?.value || false;
    },
    [projectSettings]
  );

  return { checkSetting, keys: SETTINGS_KEYS };
};
