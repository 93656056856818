import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// component
import PrivateRoute from "./AppPrivateRoutes";
import Loader from "Components/UI/Loader";
import { useProjectSettings } from "Hooks/useProjectSettings";
import { routes } from "Library/Constants";

// Pages
const Login = lazy(() => import("Pages/AuthModule/Login"));
const ProcoreAuth = lazy(() => import("Pages/AuthModule/Procore/ProcoreAuth"));
const UserDeleteGuidance = lazy(() => import("Pages/UserDeleteGuidance"));

const ChooseProject = lazy(() => import("Pages/ChooseProject"));
const Dashboard = lazy(() => import("Pages/DashBoardModule"));
const UserManagement = lazy(() => import("Pages/UserManagement"));

const MyOrganization = lazy(() => import("Pages/ProfileModule/MyOrganization"));
const MyAccount = lazy(() => import("Pages/ProfileModule/MyAccount"));

const SiteDetail = lazy(() => import("Pages/SiteManagment/SiteDetail"));
const ProjectSetting = lazy(() => import("Pages/SiteManagment/Settings"));
const Availability = lazy(() => import("Pages/SiteManagment/Availability"));

const BookingFormModule = lazy(() => import("Pages/BookingFormModule"));
const BookingList = lazy(() => import("Pages/BookingList"));
const CalendarComponent = lazy(() => import("Pages/Calendar"));
const VehicleTracking = lazy(() => import("Pages/VehicleTracking"));
const NotificationPreference = lazy(
  () => import("Pages/NotificationPreference")
);
const BookingDetailModal = lazy(
  () => import("Components/Common/Modal/BookingDetailModal")
);

export interface IAppRoutesProps {}

export default function AppRoutes() {
  const { checkSetting, keys } = useProjectSettings();
  const isVehicleTrackingVisible = checkSetting(keys.VIEW_VEHICLE_TRACKING);

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route
              path={routes.auth.procoreWebhookUrl}
              element={<ProcoreAuth />}
            />
            <Route
              path={routes.auth.choseProject}
              element={<ChooseProject />}
            />
            <Route path={routes.dashboard.home} element={<Dashboard />} />
            <Route
              path={routes.dashboard.userManagement}
              element={<UserManagement />}
            />
            <Route
              path={routes.dashboard.siteDetails}
              element={<SiteDetail />}
            />
            <Route
              path={routes.dashboard.settings}
              element={<ProjectSetting />}
            />
            <Route
              path={routes.dashboard.settingsProcore}
              element={<ProjectSetting />}
            />
            <Route
              path={routes.dashboard.availability}
              element={<Availability />}
            />
            <Route
              path={routes.dashboard.calendar}
              element={<CalendarComponent />}
            />
            <Route
              path={routes.dashboard.bookingsList}
              element={<BookingList />}
            />
            <Route
              path={routes.dashboard.bookingCreate}
              element={<BookingFormModule />}
            />
            {isVehicleTrackingVisible && (
              <Route
                path={routes.dashboard.vehicleTracking}
                element={<VehicleTracking />}
              />
            )}
            <Route
              path={routes.dashboard.bookingDetails}
              element={<BookingDetailModal />}
            />
            <Route
              path={routes.profile.organization}
              element={<MyOrganization />}
            />
            <Route path={routes.profile.account} element={<MyAccount />} />
            <Route
              path={routes.profile.notification}
              element={<NotificationPreference />}
            />
            <Route
              path={"/"}
              element={<Navigate replace to={routes.auth.choseProject} />}
            />
            <Route
              path="*"
              element={<Navigate replace to={routes.auth.choseProject} />}
            />
          </Route>

          <Route path={routes.auth.login} element={<Login />} />
          <Route
            path={routes.userDeleteGuidance}
            element={<UserDeleteGuidance />}
          />

          <Route
            path="*"
            element={<Navigate replace to={routes.auth.login} />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
