import ActionType from "../ActionType";

export const setUserDetails = (data: object) => ({
  type: ActionType.SET_USER_DETAILS,
  data,
});
export const setProjectDetails = (data: object) => ({
  type: ActionType.SET_PROJECT_DETAILS,
  data,
});
export const setFlags = (data: object) => ({
  type: ActionType.SET_FLAGS,
  data,
});
