import { LanguageAction } from "../Actions/languageAction";
import ActionType from "../Actions/ActionType";
import { constants, ls_vars } from "../../Library/Constants";
import { getLocalStorage } from "../../Network/ApiService";

const intialState = {
  language: getLocalStorage(ls_vars.USER_PREFERENCES)?.language?.code,
};

const language = (state: any = intialState, action: LanguageAction) => {
  switch (action.type) {
    case ActionType.SET_LANGUAGE:
      return {
        ...state,
        language: action.data,
      };
    default:
      return state;
  }
};
export default language;
