enum ActionType {
  SET_LANGUAGE = "SET_LANGUAGE",
  SET_USER_DETAILS = "SET_USER_DETAILS",
  SET_INVITE_USER_DETAILS = "SET_INVITE_USER_DETAILS",
  SET_PROJECT_DETAILS = "SET_PROJECT_DETAILS",
  SET_SIDEBAR_DETAILS = "SET_SIDEBAR_DETAILS",
  SET_LOADER = "SET_LOADER",
  SET_USER_FILTER = "SET_USER_FILTER",
  SET_SEARCH = "SET_SEARCH",
  SET_BOOKING_DETAILS = "SET_BOOKING_DETAILS",
  SET_BOOKING_TYPE = "SET_BOOKING_TYPE",
  SHOW_SITE_PLAN = "SHOW_SITE_PLAN",
  UPDATE_USER = "UPDATE_USER",
  SET_BOOKING_LIST_FILTER = "SET_BOOKING_LIST_FILTER",
  SET_BOOKING_DETAIL = "SET_BOOKING_DETAIL",
  SET_SITE_ID = "SET_SITE_ID",
  SET_PROJECT_SETTINGS = "SET_PROJECT_SETTINGS",
  SET_PROCORE_PRO_FEATURE_DATA = "SET_PROCORE_PRO_FEATURE_DATA",
  SET_BOOKING_COLUMNS = "SET_BOOKING_COLUMNS",
  SET_BOOKING_COLUMN = "SET_BOOKING_COLUMN",
  SET_BOOKING_FIELDS = "SET_BOOKING_FIELDS",
  SET_FLAGS = "SET_FLAGS",
}

export default ActionType;
