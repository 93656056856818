import React, { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

// helper
import { isAuth } from "./Library/Utils/Auth";
import { constants, ls_vars, routes } from "Library/Constants";
import { useApiCall } from "Hooks/useApiCall";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { authApiUser } from "Network/Core/AuthModule/auth";
import { setInviteUserDetails } from "Store/Actions/InviteUserModule/InviteUserActionCreatore";
import { getLocalStorage, setLocalStorage } from "Network/ApiService";
import { handleError } from "Library/Utils";

export default function PrivateRoutes() {
  const location = useLocation();
  const isProjectChoosing = location.pathname === routes.auth.choseProject;
  const isAuthorized = isAuth();

  const project = getLocalStorage(ls_vars.PROJECT_REF);
  const region = getLocalStorage(ls_vars.REGION_ID);

  return isAuthorized ? <Outlet /> : <Navigate to={routes.auth.login} />;
}
