import ActionType from "../../Actions/ActionType";
import {
  SiteId,
  projectSetting,
} from "../../Actions/ProjectManageMent/SiteAction";

const initialState = {
  siteID: 0,
};

const setSiteID = (state: object = initialState, action: SiteId) => {
  switch (action.type) {
    case ActionType.SET_SITE_ID:
      return {
        ...state,
        siteID: action.data,
      };
    default:
      return state;
  }
};
export default setSiteID;
